/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, {useMemo, useState} from 'react';
import {useTable, useSortBy, usePagination, useFilters} from 'react-table';
import styled from 'styled-components';
import {matchSorter} from 'match-sorter';
import Icon from './Icon';

const Styles = styled.div`
  display: block;
  max-width: 100%;
  .panel-heading {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0;
  }
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  table {
    width: 100%;
    border: 0;
    border-spacing: 0;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 1rem;
      padding-left: 17px;
      padding-right: 17px;
      border-bottom: 1px solid #eee;
      border-right: 1px solid #eee;
      width: 1%;
      &.collapse {
        width: 0.0000000001%;
      }
      :last-child {
        border-right: 0;
      }
    }
    th {
      background-color: #f5f5f5;
      border-bottom: 1px solid #eee;
    }
    td {
      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }
  .pagination {
    width: 100%;
    padding: 15px 20px;
    margin: 0;
  }
`;

// Define a default UI for filtering
function DefaultColumnFilter({
  column: {filterValue, preFilteredRows, setFilter},
}) {
  const count = preFilteredRows.length;
  return (
    <input
      className="form-control"
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function SelectColumnFilter({
  column: {filterValue, setFilter, preFilteredRows, id},
}) {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <select
      className="form-control"
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}>
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={option + String(i)} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

function HabibiTable({
  formOne,
  formTwo,
  formThree,
  title,
  columns,
  data,
  rowSize,
}) {
  const [search, setSearch] = useState('');

  data = React.useMemo(() => {
    if (search) {
      return matchSorter(data, search, {keys: Object.keys(data[0])});
    }
    return data;
  }, [search, data]);

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // rows,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize},
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: typeof rowSize !== 'undefined' ? rowSize[0] : 10,
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const firstPageRows = page.slice(
    0,
    typeof rowSize !== 'undefined' ? rowSize[pageSize] : 20
  );
  const firstPageEmptyRows = [...Array(pageSize - firstPageRows.length)];
  return (
    <Styles>
      <div className="panel panel-default">
        <div style={{paddingLeft: 20, paddingRight: 20}}>
          <div className="row row-cards">
            {formOne == null ? null : (
              <div
                className={
                  formOne !== null && formTwo !== null && formThree !== null
                    ? 'col-md-3'
                    : formOne !== null && formTwo !== null
                    ? 'col-md-4'
                    : 'col-md-6'
                }>
                <label>
                  {headerGroups[0].headers[formOne].render('Header')}
                </label>
                <div>{headerGroups[0].headers[formOne].render('Filter')}</div>
              </div>
            )}

            {formTwo == null ? null : (
              <div
                className={
                  formOne !== null && formTwo !== null && formThree !== null
                    ? 'col-md-3'
                    : formOne !== null && formTwo !== null
                    ? 'col-md-4'
                    : 'col-md-6'
                }>
                <label>
                  {headerGroups[0].headers[formTwo].render('Header')}
                </label>
                <div>{headerGroups[0].headers[formTwo].render('Filter')}</div>
              </div>
            )}

            {formThree == null ? null : (
              <div
                className={
                  formOne !== null && formTwo !== null && formThree !== null
                    ? 'col-md-3'
                    : formOne !== null && formTwo !== null
                    ? 'col-md-4'
                    : 'col-md-6'
                }>
                <label>
                  {headerGroups[0].headers[formThree].render('Header')}
                </label>
                <div>{headerGroups[0].headers[formThree].render('Filter')}</div>
              </div>
            )}

            <div
              className={
                formOne !== null && formTwo !== null && formThree !== null
                  ? 'col-md-3 form-group'
                  : formOne !== null && formTwo !== null
                  ? 'col-md-4 form-group'
                  : formOne !== null
                  ? 'col-md-6 form-group'
                  : 'col-md-12 form-group'
              }>
              <label style={{fontWeight: 600}}>Cari</label>
              <div className="input-icon mb-3">
                <input
                  type="text"
                  className="form-control"
                  onChange={e => setSearch(e.target.value)}
                  placeholder={`Cari ${title}`}
                />
                <span className="input-icon-addon">
                  <Icon name="Search" size={18} />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div style={{margin: 0, padding: 0, paddingTop: 10}}>
          <table
            {...getTableProps()}
            className="table table-striped card-table table-vcenter tableWrap">
            {/* Table head */}
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      {...column.getHeaderProps({
                        style: {minWidth: column.minWidth, width: column.width},
                      })}>
                      <div className="d-flex justify-content-between">
                        <span>{column.render('Header')}</span>
                        <span
                          {...column.getSortByToggleProps()}
                          className="ml-auto">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Icon name="BarChart" size={15} />
                            ) : (
                              <Icon name="BarChart" size={15} />
                            )
                          ) : (
                            <Icon name="BarChart" size={15} />
                          )}
                        </span>
                      </div>
                      {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            {/* Table body */}
            <tbody {...getTableBodyProps()}>
              {firstPageRows.map(
                row =>
                  prepareRow(row) || (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, i) => (
                        <td
                          // eslint-disable-next-line react/no-array-index-key
                          key={i}
                          {...cell.getCellProps({
                            style: {
                              minWidth: cell.column.minWidth,
                              width: cell.column.width,
                            },
                          })}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  )
              )}
              {firstPageEmptyRows.map(() => (
                <tr style={{height: '70px'}}>
                  {columns.map(() => (
                    <td>{}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="pagination d-flex align-items-center row">
            {/* Button next & previous */}
            <div className="col-md-4 d-flex align-items-center justify-content-center">
              <button
                aria-label="Goto Previous Button"
                type="button"
                style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                className="btn btn-gray"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}>
                <Icon name="ChevronsLeft" size={18} />
              </button>
              <button
                aria-label="Previous Button"
                type="button"
                style={{borderLeft: 0}}
                className="btn btn-gray btn-square"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}>
                <Icon name="ChevronLeft" size={18} />
              </button>
              <button
                aria-label="Next Page Button"
                type="button"
                style={{borderLeft: 0}}
                className="btn btn-gray btn-square"
                onClick={() => nextPage()}
                disabled={!canNextPage}>
                <Icon name="ChevronRight" size={18} />
              </button>
              <button
                aria-label="Goto Page Button"
                type="button"
                style={{
                  borderLeft: 0,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                className="btn btn-gray"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}>
                <Icon name="ChevronsRight" size={18} />
              </button>
            </div>

            {/* Text result */}
            <div className="col-md-4 d-flex align-items-center justify-content-center">
              Page {pageIndex + 1} of {pageOptions.length} pages
              <div
                className="d-block d-sm-none"
                style={{marginTop: 20, marginBottom: 20}}
              />
            </div>

            {/* Show entries select */}
            <div className="col-md-4 d-flex align-items-center justify-content-center">
              <span>Show</span>
              <select
                className="form-select"
                style={{marginRight: 10, marginLeft: 10, width: 100}}
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value));
                }}>
                {(typeof rowSize !== 'undefined' ? rowSize : [10, 50, 100]).map(
                  pageSize => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  )
                )}
              </select>
              <span>entries</span>
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
}

export {HabibiTable, SelectColumnFilter};
