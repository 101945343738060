import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import Swal from 'sweetalert2';
import Card from '../../components/SimpleCard';
import useUserStore from '../../stores/Users';
import {newAPI} from '../../services/baseAPI';
import Icon from '../../components/Icon';

function Password() {
  const token = useUserStore(state => state.token);
  const id = useUserStore(state => state.user.id);
  const email = useUserStore(state => state.user.email);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const {register, errors, handleSubmit, watch} = useForm({
    defaultValues: {
      old_password: '',
      new_password: '',
      confirm_password: '',
    },
  });

  const oldPassword = watch('old_password');
  const newPassword = watch('new_password');
  const confirmPassword = watch('confirm_password');

  const changePassword = async () => {
    try {
      const body = {
        id,
        email,
        oldPassword,
        newPassword,
      };

      const response = await newAPI.post(
        `/global/v1/users/change-password`,
        body,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      Swal.fire('Berhasil', 'Password berhasil diubah', 'success');
    } catch (error) {
      console.error(error);
      Swal.fire(
        'Gagal',
        error.response?.data?.message || 'Gagal merubah password',
        'error'
      );
    }
  };

  return (
    <div className="container-xl">
      <div className="row">
        <Card title="Ubah Password">
          <form onSubmit={handleSubmit(changePassword)}>
            <div className="row row-decks row-cards">
              <div className="row">
                {/* Password lama */}
                <div className="col-md-6 mt-3">
                  <label htmlFor="old_password" className="form-label">
                    Password Lama
                  </label>
                  <div className="input-group input-group-flat">
                    <input
                      ref={register({required: true})}
                      name="old_password"
                      type={showOldPassword ? 'text' : 'password'}
                      className="form-control"
                      placeholder="Masukan Password Lama"
                    />
                    <span
                      className="btn input-group-text"
                      onClick={() => setShowOldPassword(prev => !prev)}
                      aria-hidden="true">
                      <Icon
                        name={showOldPassword ? 'EyeOff' : 'Eye'}
                        size={16}
                        style={{marginLeft: 15}}
                      />
                    </span>
                  </div>
                  {errors.old_password && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Password Lama wajib diisi
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                {/* Password Baru */}
                <div className="col-md-6 mt-3">
                  <label htmlFor="new_password" className="form-label">
                    Password Baru
                  </label>
                  <div className="input-group input-group-flat">
                    <input
                      ref={register({required: true})}
                      name="new_password"
                      type={showNewPassword ? 'text' : 'password'}
                      className="form-control"
                      placeholder="Masukan Password Baru"
                    />
                    <span
                      className="btn input-group-text"
                      onClick={() => setShowNewPassword(prev => !prev)}
                      aria-hidden="true">
                      <Icon
                        name={showNewPassword ? 'EyeOff' : 'Eye'}
                        size={16}
                        style={{marginLeft: 15}}
                      />
                    </span>
                  </div>
                  {errors.new_password && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Password Baru wajib diisi
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                {/* Konfirmasi Password */}
                <div className="col-md-6 mb-3 mt-3">
                  <label htmlFor="confirm_password" className="form-label">
                    Konfirmasi Password
                  </label>
                  <div className="input-group input-group-flat">
                    <input
                      disabled={newPassword === ''}
                      ref={register({required: true})}
                      name="confirm_password"
                      type={showConfirmPassword ? 'text' : 'password'}
                      className="form-control"
                      placeholder="Masukan Konfirmasi Password Baru"
                    />
                    <span
                      className="btn input-group-text"
                      onClick={() => setShowConfirmPassword(prev => !prev)}
                      aria-hidden="true">
                      <Icon
                        name={showConfirmPassword ? 'EyeOff' : 'Eye'}
                        size={16}
                        style={{marginLeft: 15}}
                      />
                    </span>
                  </div>
                  {errors.confirm_password && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Konfirmasi Password wajib diisi
                    </div>
                  )}
                  {confirmPassword !== newPassword && confirmPassword !== '' ? (
                    <div className="pt-1" style={{color: 'red'}}>
                      Password Tidak Sama
                    </div>
                  ) : null}
                  {confirmPassword === newPassword &&
                  confirmPassword !== '' &&
                  newPassword !== '' ? (
                    <div className="pt-1" style={{color: 'green'}}>
                      Password Baru Sesuai
                    </div>
                  ) : null}
                </div>
              </div>

              {/* tombol */}
              <div className="col-md-12 mt-3">
                <input
                  disabled={
                    confirmPassword !== newPassword || confirmPassword === ''
                  }
                  className="btn w-100 btn-primary px-5"
                  type="submit"
                  value="Ubah Password"
                />
              </div>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
}

export default Password;
