/* eslint-disable import/prefer-default-export */
import moment from 'moment';

const taskFileRenamer = (file, typeFile, taskName, reporter) => {
  const fileExtension = file.name.split('.').pop();
  const type = typeFile.toLowerCase().replace(/ /g, '_');
  const task = taskName.toLowerCase().replace(/ /g, '_');
  const reporterFirstName = reporter.first_name
    ?.toLowerCase()
    .replace(/ /g, '_');
  const reporterLastName = reporter.last_name?.toLowerCase().replace(/ /g, '_');
  const reporterName = `${reporterFirstName}-${reporterLastName}`;
  const unixTimestamp = moment().unix();

  const newFileName = `${type}-${task}-${reporterName}-${unixTimestamp}.${fileExtension}`;
  const renamedFile = new File([file], newFileName, {type: file.type});

  return renamedFile;
};

export {taskFileRenamer};
