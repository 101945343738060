import {newAPI, oldAPI, stomataAPI} from '../services/baseAPI';

const fetchArticle = async () => {
  try {
    const response = await newAPI.get('/articles');
    return response.data;
  } catch (error) {
    console.error(`error ${error}`);
    throw error;
  }
};

const fetchDisease = async token => {
  try {
    const response = await oldAPI.get('/v2/pestdisease', {
      headers: {Accept: 'application/json'},
    });
    return response.data.data;
  } catch (error) {
    console.error(`error ${error}`);
    throw error;
  }
};

const fetchGrowth = async () => {
  try {
    const response = await newAPI.get('/growth');
    return response.data;
  } catch (error) {
    console.error(`error ${error}`);
    throw error;
  }
};

const fetchPlant = async () => {
  try {
    const response = await newAPI.get('/plants');
    return response.data;
  } catch (error) {
    console.error(`error ${error}`);
    throw error;
  }
};

const fetchCultivation = async () => {
  try {
    const response = await oldAPI.get('/v2/articles');
    return response.data.data;
  } catch (error) {
    console.error(`error ${error}`);
    throw error;
  }
};

const fetchFertilizer = async token => {
  try {
    const response = await stomataAPI.get('/api/v1/fertilizer', {
      headers: {token: `${token}`},
    });
    console.log('response', response);
    return response.data.fertilizer;
  } catch (error) {
    console.error(`error ${error}`);
    throw error;
  }
};

export {
  fetchArticle,
  fetchDisease,
  fetchGrowth,
  fetchPlant,
  fetchCultivation,
  fetchFertilizer,
};
