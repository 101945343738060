import React, {useState, useEffect, useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {Link, useHistory} from 'react-router-dom';
import Swal from 'sweetalert2';
import {newAPI} from '../../services/baseAPI';
import Icon from '../../components/Icon';
import useUserStore from '../../stores/Users';
import Card from '../../components/SimpleCard';
import LoadingView from '../../components/Loading';

function FarmerCreate() {
  // State
  const ADMIN = 1;
  const OWNER = 2;
  const token = useUserStore(state => state.token);
  const users = useUserStore(state => state.user);
  const {register, errors, handleSubmit, setValue} = useForm();
  const history = useHistory();

  const [data, setData] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const storeFarmer = async params => {
    setIsLoading(true);
    const body = new FormData();
    body.append('first_name', params.first_name);
    body.append('last_name', params.last_name);
    body.append('email', params.email);
    body.append('birth_date', params.birth_date);
    body.append('gender', params.gender);
    body.append('phone', params.phone);
    body.append('city', params.city);
    body.append('password', params.password);
    body.append('status', params.status);
    newAPI
      .post('/v3/users', body, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(res => {
        console.log(`berhasil upload ${JSON.stringify(res)}`);
        Swal.fire('Saved!', 'pengguna berhasil ditambahkan!', 'success');
      })
      .catch(error => {
        console.log(`error ${error}`);
        Swal.fire('Changes are not saved', 'there is something wrong', 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const header = () => (
    <>
      <div className="page-header d-print-none">
        <div className="row align-items-center">
          <button
            aria-label="Back Button"
            type="button"
            onClick={() => history.push('/farmer')}
            className="col-2"
            style={{
              marginLeft: '8px',
              marginRight: '10px',
              height: 40,
              width: 40,
              borderRadius: 20,
              backgroundColor: 'rgba(255, 0, 0, 0)',
              borderWidth: 0,
            }}>
            <Icon name="ArrowLeft" color="#333" size={20} />
          </button>
          <div className="col">
            <div className="page-pretitle">Tambah</div>
            <h2 className="page-title">
              {users.status === ADMIN ? 'Pengguna' : 'Petani'}
            </h2>
          </div>
        </div>
      </div>
    </>
  );
  const createView = () => (
    <>
      <Card
        title={`Form Tambah ${users.status === ADMIN ? 'Pengguna' : 'Petani'}`}>
        <form onSubmit={handleSubmit(storeFarmer)}>
          <div className="row row-decks row-cards">
            {/* First Name */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Nama Depan
              </label>
              <input
                ref={register({required: true})}
                name="first_name"
                type="text"
                className="form-control"
                placeholder="Masukan nama depan"
              />
              {errors.name && (
                <div className="pt-1" style={{color: 'red'}}>
                  First Name wajib diisi
                </div>
              )}
            </div>

            {/* Last Name */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Nama Belakang
              </label>
              <input
                ref={register({required: true})}
                name="last_name"
                type="text"
                className="form-control"
                placeholder="Masukan nama belakang"
              />
              {errors.name && (
                <div className="pt-1" style={{color: 'red'}}>
                  Last Name wajib diisi
                </div>
              )}
            </div>

            {/* Email */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Email
              </label>
              <input
                ref={register({required: true})}
                name="email"
                type="email"
                className="form-control"
                placeholder="Masukan email"
              />
              {errors.name && (
                <div className="pt-1" style={{color: 'red'}}>
                  Email wajib diisi
                </div>
              )}
            </div>

            {/* Phone */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                No. Telepon
              </label>
              <input
                ref={register({required: true})}
                name="phone"
                type="text"
                className="form-control"
                placeholder="Masukan no. telpon"
              />
              {errors.name && (
                <div className="pt-1" style={{color: 'red'}}>
                  Phone wajib diisi
                </div>
              )}
            </div>

            {/* Birt Date */}
            <div className="col-md-4">
              <label htmlFor="true" className="form-label">
                Tanggal Lahir
              </label>
              <input
                ref={register({required: true})}
                name="birth_date"
                type="date"
                className="form-control"
                placeholder="Masukan tanggal lahir"
              />
              {errors.birth_date && (
                <div className="pt-1" style={{color: 'red'}}>
                  Birt Date wajib diisi
                </div>
              )}
            </div>

            {/* Gender */}
            <div className="col-md-4">
              <label htmlFor="true" className="form-label">
                Jenis Kelamin
              </label>
              <select
                name="gender"
                ref={register({required: true})}
                className="form-select form-control">
                <option value="Male">Laki-Laki</option>
                <option value="Female">Perempuan</option>
              </select>
              {errors.gender && (
                <div className="pt-1" style={{color: 'red'}}>
                  Gender wajib diisi
                </div>
              )}
            </div>

            {/* City */}
            <div className="col-md-4">
              <label htmlFor="true" className="form-label">
                Asal/Kota
              </label>
              <input
                ref={register({required: true})}
                name="city"
                type="text"
                className="form-control"
                placeholder="Masukan asal/kota"
              />
              {errors.name && (
                <div className="pt-1" style={{color: 'red'}}>
                  City wajib diisi
                </div>
              )}
            </div>

            {/* Password */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Password
              </label>
              <input
                ref={register({required: true})}
                name="password"
                type="text"
                className="form-control"
                placeholder="Masukan Password"
              />
              {errors.modem && (
                <div className="pt-1" style={{color: 'red'}}>
                  Password wajib diisi
                </div>
              )}
            </div>

            {/* Status */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Status
              </label>
              <select
                name="status"
                ref={register({required: true})}
                className="form-select form-control">
                {users.status === ADMIN ? (
                  <>
                    <option value="1">Admin</option>
                    <option value="2">Owner</option>
                    <option value="3">Petani</option>
                    <option value="4">Penjual</option>
                    <option value="6">Agronomist</option>
                    <option value="7">Engineer & Mekanikal</option>
                  </>
                ) : (
                  <option value="3">Petani</option>
                )}
              </select>
              {errors.status && (
                <div className="pt-1" style={{color: 'red'}}>
                  Status wajib diisi
                </div>
              )}
            </div>

            {/* tombol */}
            <div className="col-md-12">
              <input
                className="btn w-100 btn-primary px-5"
                type="submit"
                value="Tambah Pengguna"
              />
            </div>
          </div>
        </form>
      </Card>
    </>
  );

  return (
    <div className="container-xl">
      {header()}

      <div className="row">
        {/* content */}
        {isLoading ? (
          <div className="col-md-12">
            <LoadingView />
          </div>
        ) : (
          createView()
        )}
      </div>
    </div>
  );
}

export default FarmerCreate;
