/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import Select from 'react-select';
import axios from 'axios';
import {Marker} from 'react-leaflet';

import Swal from 'sweetalert2';
import useUserStore from '../../stores/Users';
import LoadingView from '../../components/Loading';
import Card from '../../components/SimpleCard';
import {newAPI} from '../../services/baseAPI';
import Icon from '../../components/Icon';
import {MapsExpert} from '../../components/Maps';

function FarmCreate() {
  const token = useUserStore(state => state.token);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    onBlur,
    errors,
    setValue,
    setError,
    clearErrors,
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState(null);
  const [device, setDevice] = useState({
    grow: null,
    dose: null,
    hws: null,
    hwq: null,
    hfm: null,
    hws_climate: null,
    cam: {
      cam_status: null,
      cam_master: null,
    },
    link: {
      link_status: null,
      link_type: null,
    },
    bardi: {
      bardi_status: null,
      bardi_master: null,
    },
  });
  // const [latLng, setLatLng] = useState(lat:-6.9353636194650825, lng: 107.67278017261582 });
  const markerRef = useRef(null);
  const [lati, setLati] = useState(-6.9353636194650825);
  const [long, setLong] = useState(107.67278017261582);
  const [users, setUsers] = useState({owner: [], farmer: []});
  const [internet, setInternet] = useState({no_hp: null, internet: null});

  const fetchUsers = async () => {
    setIsLoading(true);
    await axios
      .all([newAPI.get('v3/users/owner'), newAPI.get('v3/users/farmer')], {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then(res => {
        const owner = res[0].data;
        const farmer = res[1].data;
        const arrayOwner = [];
        const arrayFarmer = [];
        owner.forEach(e => {
          arrayOwner.push({
            value: e.id,
            label: `${e.first_name} ${e.last_name}`,
          });
        });
        farmer.forEach(e => {
          arrayFarmer.push({
            value: e.id,
            label: `${e.first_name} ${e.last_name}`,
          });
        });
        setUsers({owner: arrayOwner, farmer: arrayFarmer});
      })
      .catch(error => console.log(`error ${error}`))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    register('owner');
    register('farmer');
    register('water_sources');
    register('internet');
    register('status');
    register('method_purchasing');
    register('field_type');
    fetchUsers();
  }, []);

  const storeFarm = params => {
    setIsLoading(true);

    // console.log(JSON.stringify(device));
    const nohp = [];
    if (params.modem !== undefined) {
      nohp.push(params.modem);
    } else {
      nohp.push(null);
    }

    if (
      params.status !== undefined &&
      params.internet !== undefined &&
      params.method_purchasing !== undefined &&
      params.owner !== undefined &&
      params.field_type !== undefined &&
      params.water_sources !== undefined
    ) {
      const body = new FormData();
      body.append('name', params.name);
      body.append('device', JSON.stringify(device));
      // body.append('grow_zone', 4);
      body.append('status', params.status);
      body.append('hws_sn', params.hws_sn);
      body.append('code_farm', params.code_farm.toUpperCase());
      body.append('farmer', params.farmer);
      body.append('owner', params.owner);
      body.append('latitude', params.latitude);
      body.append('longitude', params.longitude);
      body.append('method_purchasing', params.method_purchasing);
      body.append('modem', nohp[0]);
      body.append('farm_type', 'Openfield');
      body.append('farm_method', 'Tanah');
      body.append('dosis_pump', 13);
      body.append('water_tank', 13);
      body.append('city', params.city);
      body.append('internet', params.internet);
      body.append('field_type', params.field_type);
      body.append('field_height', params.field_height);
      body.append('field_slope', params.field_slope);
      body.append('total_farmer', params.total_farmer);
      body.append('water_sources', params.water_sources);
      body.append('power', params.power);
      body.append('garansi', params.garansi);
      body.append('image', image);

      newAPI
        .post('/v3/farms', body, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          console.log(`berhasil upload ${JSON.stringify(res)}`);
          Swal.fire('Successfully added!', res.data.message, 'success');
        })
        .catch(error => {
          console.error(`error ${error}`);
          Swal.fire(
            'Changes are not saved!',
            error.response.data.message,
            'error'
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      Swal.fire(
        'Tidak bisa menambah zona',
        'masih terdapat data kosong',
        'error'
      );
      setIsLoading(false);
    }
  };

  const fetchFarmIsAvailable = async farmCode => {
    setIsLoading(false);
    await newAPI
      .get(`/v3/farms/exist/${farmCode}`, {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then(() => {
        clearErrors('farm_code_used');
      })
      .catch(error => {
        setError('farm_code_used', error);
        console.error(`error ${error}`);
      })
      .finally(() => setIsLoading(false));
  };

  const handleCheckFarmCode = async event => {
    const farmCode = event.target.value;
    await fetchFarmIsAvailable(farmCode);
  };

  const getMarkerLatLng = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setLati(marker.getLatLng().lat);
          setLong(marker.getLatLng().lng);
        }
      },
    }),
    []
  );

  // USER INTERFACE

  const header = () => (
    <>
      <div className="page-header d-print-none">
        <div className="row align-items-center">
          <button
            aria-label="Back Button"
            type="button"
            onClick={() => history.push('/farm')}
            className="col-2"
            style={{
              marginLeft: '8px',
              marginRight: '10px',
              height: 40,
              width: 40,
              borderRadius: 20,
              backgroundColor: 'rgba(255, 0, 0, 0)',
              borderWidth: 0,
            }}>
            <Icon name="ArrowLeft" color="#333" size={20} />
          </button>
          <div className="col">
            <div className="page-pretitle">Tambah</div>
            <h2 className="page-title">Kebun</h2>
          </div>
        </div>
      </div>
    </>
  );

  const createView = () => (
    <>
      <form onSubmit={handleSubmit(storeFarm)}>
        <div className="row row-decks row-cards">
          <Card title="Tambah Data Kebun">
            <div className="row row-decks row-cards">
              {/* nama kebun */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Nama Kebun
                  <span style={{color: 'red'}}>*</span>
                </label>
                <input
                  ref={register({required: true})}
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="Masukan nama kebun"
                />
                {errors.name && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Nama kebun wajib diisi
                  </div>
                )}
              </div>

              {/* kode kebun */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Kode Kebun
                  <span style={{color: 'red'}}>*</span>
                </label>
                <input
                  ref={register({required: true})}
                  name="code_farm"
                  type="text"
                  className="form-control text-uppercase"
                  placeholder="Masukan kode kebun"
                  onBlur={handleCheckFarmCode}
                />
                {errors.farm_code_used && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Kode kebun sudah digunakan
                  </div>
                )}
                {errors.code_farm && (
                  <div className="pt-1" style={{color: 'red'}}>
                    kode kebun wajib diisi
                  </div>
                )}
              </div>

              {/* Kota */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Kota
                  <span style={{color: 'red'}}>*</span>
                </label>
                <input
                  ref={register({required: true})}
                  name="city"
                  type="text"
                  className="form-control"
                  placeholder="Masukan Kota"
                />
                {errors.city && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Kota wajib diisi
                  </div>
                )}
              </div>

              {/* Status */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Status
                  <span style={{color: 'red'}}>*</span>
                </label>
                <Select
                  options={[
                    {value: 1, label: 'Aktif'},
                    {value: 0, label: 'Tidak aktif'},
                  ]}
                  onChange={e => {
                    // console.log(e.value);
                    setValue('status', e.value);
                  }}
                />
                {errors.status && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Status wajib diisi
                  </div>
                )}
              </div>

              {/* Internet */}
              <div
                className={
                  internet.internet === 'wifi' || internet.internet === null
                    ? 'col-md-6'
                    : 'col-md-3'
                }>
                <label htmlFor="true" className="form-label">
                  Internet
                  <span style={{color: 'red'}}>*</span>
                </label>
                <Select
                  options={[
                    {value: 'wifi', label: 'Wifi'},
                    {value: 'hbblink', label: 'Habibi Link'},
                  ]}
                  onChange={e => {
                    // console.log(e.value);
                    setValue('internet', e.value);
                    setInternet({...internet, internet: e.value});
                  }}
                />
                {errors.internet && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Internet wajib diisi
                  </div>
                )}
              </div>

              {/* Nomer HP */}
              {internet.internet === 'hbblink' ? (
                <div className="col-md-3">
                  <label htmlFor="true" className="form-label">
                    No. Kuota Internet
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <input
                    ref={register({required: true})}
                    name="modem"
                    type="text"
                    className="form-control"
                    placeholder="Masukan No. Kuota Internet"
                    disabled={internet.internet && false}
                  />
                  {errors.modem && (
                    <div className="pt-1" style={{color: 'red'}}>
                      No. Kuota Internet wajib diisi
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}

              {/* Metode Pembelian */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Metode Pembelian
                  <span style={{color: 'red'}}>*</span>
                </label>
                <Select
                  options={[
                    {value: 'beli', label: 'Beli'},
                    {value: 'sewa', label: 'Sewa'},
                    {value: 'yarnen', label: 'Bayar Panen'},
                  ]}
                  onChange={e => {
                    // console.log(e.value);
                    setValue('method_purchasing', e.value);
                  }}
                />
                {errors.method_purchasing && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Metode wajib diisi
                  </div>
                )}
              </div>

              {/* Pemilik */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Pemilik
                  <span style={{color: 'red'}}>*</span>
                </label>
                <Select
                  options={users.owner}
                  closeMenuOnSelect
                  onChange={e => {
                    setValue('owner', e.value);
                  }}
                />
                {errors.owner && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Owner wajib diisi
                  </div>
                )}
              </div>

              {/* Petani */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Petani
                </label>
                <Select
                  options={[{value: 0, label: 'Tidak Ada'}, ...users.farmer]}
                  onChange={e => {
                    // console.log(e.value);
                    setValue('farmer', e.value);
                  }}
                />
                {errors.farmer && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Petani wajib diisi
                  </div>
                )}
              </div>

              {/* Field type */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Jenis Lahan
                  <span style={{color: 'red'}}>*</span>
                </label>
                <Select
                  options={[
                    {value: 'indoor', label: 'Indoor'},
                    {value: 'outdoor', label: 'Outdoor'},
                  ]}
                  onChange={e => {
                    // console.log(e.value);
                    setValue('field_type', e.value);
                  }}
                />
                {errors.field_type && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Jenis lahan wajib diisi
                  </div>
                )}
              </div>

              {/* Total farmer */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Jumlah Anggota Kelompok Tani
                  <span style={{color: 'red'}}>*</span>
                </label>
                <input
                  ref={register({required: true})}
                  name="total_farmer"
                  type="number"
                  className="form-control"
                  placeholder="Masukan jumlah anggota kelompok tani"
                />
                {errors.total_farmer && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Jumlah anggota kelompok tani wajib diisi
                  </div>
                )}
              </div>

              {/* Field height */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Ketinggian lahan
                  <span style={{color: 'red'}}>*</span>
                </label>
                <input
                  ref={register({required: true})}
                  name="field_height"
                  type="number"
                  className="form-control"
                  placeholder="Masukan ketinggian lahan"
                />
                {errors.field_height && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Ketinggian lahan wajib diisi
                  </div>
                )}
              </div>

              {/* Field slope */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Kemiringan lahan
                  <span style={{color: 'red'}}>*</span>
                </label>
                <input
                  ref={register({required: true})}
                  name="field_slope"
                  type="number"
                  className="form-control"
                  placeholder="Masukan kemiringan lahan"
                />
                {errors.field_slope && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Kemiringan lahan wajib diisi
                  </div>
                )}
              </div>

              {/* Power */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Daya Listrik
                  <span style={{color: 'red'}}>*</span>
                </label>
                <input
                  ref={register({required: true})}
                  name="power"
                  type="number"
                  className="form-control"
                  placeholder="Masukan daya listrik"
                />
                {errors.power && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Daya Listrik wajib diisi
                  </div>
                )}
              </div>

              {/* Water sources */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Sumber Air
                  <span style={{color: 'red'}}>*</span>
                </label>
                <Select
                  options={[
                    {value: 'sumur', label: 'Sumur'},
                    {value: 'pdam', label: 'PDAM'},
                    {value: 'sungai', label: 'Sungai'},
                    {value: 'bak', label: 'Bak Penampungan'},
                  ]}
                  onChange={e => {
                    // console.log(e.value);
                    setValue('water_sources', e.value);
                  }}
                />
                {errors.water_sources && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Sumber air wajib diisi
                  </div>
                )}
              </div>

              {/* Latitude */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Latitude
                  <span style={{color: 'red'}}>*</span>
                </label>
                <input
                  ref={register({required: true})}
                  name="latitude"
                  type="number"
                  className="form-control"
                  placeholder="Masukan Latitude"
                  onChange={item => {
                    setLati(parseFloat(item.target.value));
                    // console.log(item.target.value)
                  }}
                  value={lati}
                />
                {errors.latitude && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Latitude wajib diisi
                  </div>
                )}
              </div>

              {/* Longitude */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Longitude
                  <span style={{color: 'red'}}>*</span>
                </label>
                <input
                  ref={register({required: true})}
                  name="longitude"
                  type="number"
                  className="form-control"
                  placeholder="Masukan Longitude"
                  onChange={item => {
                    setLong(parseFloat(item.target.value));
                    // console.log(item.target.value)
                  }}
                  value={long}
                />
                {errors.longitude && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Longitude wajib diisi
                  </div>
                )}
              </div>
              <br />
              <div className="col-md-12">
                <MapsExpert
                  latitude={lati}
                  longitude={long}
                  zoom={15}
                  style={{height: '400px', width: '100%', zIndex: 0}}>
                  <Marker
                    ref={markerRef}
                    draggable
                    position={[lati, long]}
                    eventHandlers={getMarkerLatLng}
                  />
                </MapsExpert>
              </div>

              {/* gambar */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Gambar
                  <span style={{color: 'red'}}>*</span>
                </label>
                <input
                  ref={register({required: true})}
                  name="image"
                  type="file"
                  className="form-control"
                  placeholder="Masukan gambar"
                  onChange={e => {
                    setImage(e.target.files[0]);
                    setImagePreview(URL.createObjectURL(e.target.files[0]));
                  }}
                />
                {errors.image && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Gambar artikel wajib diisi
                  </div>
                )}
              </div>

              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Garansi
                  <span style={{color: 'red'}}> (dalam satuan bulan)</span>
                </label>
                <input
                  ref={register({required: true})}
                  name="garansi"
                  type="number"
                  className="form-control"
                  placeholder="Masukan garansi"
                />
                {errors.garansi && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Garansi wajib diisi
                  </div>
                )}
              </div>

              {/* preview & input gambar */}
              <div className="col-md-12">
                {imagePreview && (
                  <img
                    className="col-md-12 mx-auto d-block text-center"
                    alt="pic preview"
                    src={imagePreview}
                    style={{maxWidth: '300px', maxHeight: '300px'}}
                  />
                )}
              </div>
            </div>
          </Card>
          <Card title="Tambah Device Kebun" styleBody={{padding: 0}}>
            <div className="row row-decks row-cards">
              <div className="col-md-12">
                <table className="table table-striped">
                  <thead>
                    <tr className="align-middle">
                      <th className="text-start ps-3" width="80%">
                        Nama
                      </th>
                      <th className="text-center" width="20%">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* grow */}
                    <tr className="align-middle">
                      <td className="text-start ps-3">
                        Habibi Grow{'\n'}
                        {errors.grow && (
                          <div className="text-red">Grow wajib diisi</div>
                        )}
                      </td>
                      <td className="text-center">
                        <div className="form-switch">
                          <input
                            ref={register()}
                            name="grow"
                            type="checkbox"
                            className="form-check-input"
                            defaultValue="0"
                            onChange={e => {
                              setDevice({
                                ...device,
                                grow: e.target.checked ? true : null,
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>

                    {/* dose */}
                    <tr className="align-middle">
                      <td className="text-start ps-3">
                        Habibi Dose{'\n'}
                        {errors.dose && (
                          <div className="text-red">Dose wajib diisi</div>
                        )}
                      </td>
                      <td className="text-center">
                        <div className="form-switch">
                          <input
                            ref={register()}
                            name="dose"
                            type="checkbox"
                            className="form-check-input"
                            defaultValue="0"
                            onChange={e => {
                              setDevice({
                                ...device,
                                dose: e.target.checked ? true : null,
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>

                    {/* hws */}
                    <tr className="align-middle">
                      <td className="text-start ps-3">
                        Habibi Weather Station{'\n'}
                        {device.hws && (
                          <div className="mt-1 col-md-4 col-md-offset-8 input-with-text">
                            <span className="form-check-label me-2">
                              SN<span className="text-red">*</span>
                            </span>
                            <input
                              ref={register({required: true})}
                              name="hws_sn"
                              type="text"
                              className="form-control"
                              placeholder="Masukan Serial number"
                            />
                            {errors.hws_sn && (
                              <div className="text-red">
                                Serial number wajib diisi
                              </div>
                            )}
                          </div>
                        )}
                      </td>
                      <td className="text-center">
                        <div className="form-switch">
                          <input
                            ref={register()}
                            name="hws"
                            type="checkbox"
                            className="form-check-input"
                            defaultValue="0"
                            onChange={e => {
                              setDevice({
                                ...device,
                                hws: e.target.checked ? true : null,
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>

                    {/* hws + climate */}
                    <tr className="align-middle">
                      <td className="text-start ps-3">
                        Habibi Weather Station + Climate
                      </td>
                      <td className="text-center">
                        <div className="form-switch">
                          <input
                            ref={register()}
                            name="hws_climate"
                            type="checkbox"
                            className="form-check-input"
                            defaultValue="0"
                            onChange={e => {
                              setDevice({
                                ...device,
                                hws_climate: e.target.checked ? true : null,
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>

                    {/* hwq / water quality */}
                    <tr className="align-middle">
                      <td className="text-start ps-3">Habibi Water Quality</td>
                      <td className="text-center">
                        <div className="form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={device.hwq && 1}
                            onChange={e => {
                              setDevice({
                                ...device,
                                hwq: e.target.checked ? true : null,
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>

                    {/* hfm / flowmeter */}
                    <tr className="align-middle">
                      <td className="text-start ps-3">Habibi Flowmeter</td>
                      <td className="text-center">
                        <div className="form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={device.hfm && 1}
                            onChange={e => {
                              setDevice({
                                ...device,
                                hfm: e.target.checked ? true : null,
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>

                    {/* camera */}
                    <tr className="align-middle">
                      <td className="text-start ps-3">
                        Habibi Cam{'\n'}
                        {device.cam.cam_status && (
                          <div className="mt-1 col-md-4 col-md-offset-8 input-with-text">
                            <span className="form-check-label me-2">
                              Master
                              <span className="text-red">*</span>
                            </span>
                            <input
                              ref={register({required: true})}
                              name="cam_master"
                              type="text"
                              className="form-control"
                              onChange={e => {
                                setDevice({
                                  ...device,
                                  cam: {
                                    ...device.cam,
                                    cam_master: e.target.value,
                                  },
                                });
                              }}
                              placeholder="Masukan nama"
                            />
                            {errors.cam_master && (
                              <div className="text-red">Nama wajib diisi</div>
                            )}
                          </div>
                        )}
                      </td>
                      <td className="text-center">
                        <div className="form-switch">
                          <input
                            ref={register()}
                            name="cam"
                            type="checkbox"
                            className="form-check-input"
                            defaultValue="0"
                            onChange={e => {
                              setDevice({
                                ...device,
                                cam: {
                                  ...device.cam,
                                  cam_status: e.target.checked ? true : null,
                                },
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>

                    {/* link */}
                    <tr className="align-middle">
                      <td className="text-start ps-3">
                        Habibi Link{'\n'}
                        {device.link.link_status && (
                          <div className="mt-1 col-md-6 col-md-offset-3 input-with-text">
                            <span className="form-check-label me-2">
                              Jenis
                              <span className="text-red">*</span>
                            </span>
                            <Select
                              className="w-100"
                              placeholder="Pilih jenis"
                              isMulti
                              options={[
                                {value: 'dashboard', label: 'Dashboard'},
                                {value: 'apk', label: 'Aplikasi'},
                              ]}
                              onChange={e => {
                                const feature = [];
                                for (let i = 0; i < e.length; i += 1) {
                                  feature.push(e[i].value);
                                }
                                setValue('link_type', feature);
                                setDevice({
                                  ...device,
                                  link: {...device.link, link_type: feature},
                                });
                              }}
                            />
                            {errors.link_type && (
                              <div className="pt-1" style={{color: 'red'}}>
                                Tipe link wajib dipilih
                              </div>
                            )}
                          </div>
                        )}
                      </td>
                      <td className="text-center">
                        <div className="form-switch">
                          <input
                            ref={
                              internet.internet === 'hbblink'
                                ? register({required: true})
                                : register()
                            }
                            name="link"
                            type="checkbox"
                            className="form-check-input"
                            defaultValue="0"
                            onChange={e => {
                              setDevice({
                                ...device,
                                link: {
                                  ...device.link,
                                  link_status: e.target.checked ? true : null,
                                },
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>

                    {/* bardi */}
                    <tr className="align-middle">
                      <td className="text-start ps-3">
                        Bardi{'\n'}
                        {device.bardi.bardi_status && (
                          <div className="mt-1 col-md-6 col-md-offset-3 input-with-text">
                            <span className="form-check-label me-2">
                              Master
                              <span className="text-red">*</span>
                            </span>
                            <input
                              ref={register({required: true})}
                              name="bardi_master"
                              type="text"
                              className="form-control"
                              onChange={e => {
                                setDevice({
                                  ...device,
                                  bardi: {
                                    ...device.bardi,
                                    bardi_master: e.target.value,
                                  },
                                });
                              }}
                              placeholder="Masukan nama"
                            />
                            {errors.bardi_master && (
                              <div className="pt-1" style={{color: 'red'}}>
                                Nama wajib diisi
                              </div>
                            )}
                          </div>
                        )}
                      </td>
                      <td className="text-center">
                        <div className="form-switch">
                          <input
                            ref={register()}
                            name="bardi"
                            type="checkbox"
                            className="form-check-input"
                            defaultValue="0"
                            onChange={e => {
                              setDevice({
                                ...device,
                                bardi: {
                                  ...device.bardi,
                                  bardi_status: e.target.checked ? true : null,
                                },
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="col-md-12">
                <input
                  className="btn w-100 btn-primary px-5"
                  type="submit"
                  value="Tambah Kebun"
                  disabled={errors.farm_code_used}
                />
                <div className="pt-1" style={{color: 'red'}}>
                  wajib mengisi input yang bertanda *
                </div>
              </div>
            </div>
          </Card>
        </div>
      </form>
    </>
  );

  return (
    <div className="container-xl">
      {header()}

      <div className="row">
        {/* content */}
        {isLoading ? (
          <div className="col-md-12">
            <LoadingView />
          </div>
        ) : (
          createView()
        )}
      </div>
    </div>
  );
}

export default FarmCreate;
