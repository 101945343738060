/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-underscore-dangle */

import Swal from 'sweetalert2';
import axios from 'axios';
import {saveAs} from 'file-saver';
import {stomataAPI} from '../services/baseAPI';
import {taskFileRenamer} from '../utils/renamer';

const uploadFileReport = async (token, params, report, reporter) => {
  try {
    Swal.fire({
      title: 'Please wait...',
      text: 'Your upload is in progress',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    // RENAME THE FILE
    const renamedFile = taskFileRenamer(
      report.file,
      report.typeFile.value,
      params.name,
      reporter
    );

    // GET GOOGLE DRIVE ACCESS TOKEN
    const driveAuth = await stomataAPI.get('/api/v1/google-drive/auth', {
      headers: {
        'Content-Type': 'application/json',
        token,
      },
    });

    // FIND & CREATE FOLDER
    const body = {
      id: params._id,
      farmName: params.farmInfo.name,
    };
    const uploadFolder = await stomataAPI.post(
      '/api/v1/google-drive/upload-folder',
      body,
      {
        headers: {
          'Content-Type': 'application/json',
          token,
        },
      }
    );

    // GET UPLOAD FILE LINK
    const fileMetadata = {
      name: renamedFile.name,
      mimeType: renamedFile.type,
      parents: [uploadFolder.data.folderId],
    };
    const initUpload = await axios.post(
      'https://www.googleapis.com/upload/drive/v3/files?uploadType=resumable',
      fileMetadata,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${driveAuth.data.accessToken}`,
        },
      }
    );
    const uploadURL = initUpload.headers.location;

    // UPLOAD FILE TO GOOGLE DRIVE
    const uploadFile = await axios.put(uploadURL, renamedFile, {
      headers: {
        'Content-Type': renamedFile.type,
      },
    });

    // SAVE FILE TO DATABASE
    const bodySave = {
      id: params._id,
      typeFile: report.typeFile.value,
      fileId: uploadFile.data.id,
      fileName: uploadFile.data.name,
    };
    const response = await stomataAPI.post(
      '/api/v1/tasks/update-progress',
      bodySave,
      {
        headers: {
          'Content-Type': 'application/json',
          token,
        },
      }
    );

    Swal.close();
    Swal.fire('Berhasil!', 'Upload tugas berhasil', 'success');
  } catch (error) {
    console.error(error);
    Swal.close();
    Swal.fire('Gagal', 'there is something wrong', 'error');
  }
};

const downloadFileReport = async (token, report) => {
  try {
    Swal.fire({
      title: 'Please wait...',
      text: 'Your download is in progress',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    // GET GOOGLE DRIVE ACCESS TOKEN
    const driveAuth = await stomataAPI.get('/api/v1/google-drive/auth', {
      headers: {
        'Content-Type': 'application/json',
        token,
      },
    });

    // GET FILE DOWNLOAD LINK
    const downloadFile = await axios.get(
      `https://www.googleapis.com/drive/v3/files/${report.fileId}?alt=media`,
      {
        headers: {
          Authorization: `Bearer ${driveAuth.data.accessToken}`,
        },
        responseType: 'blob',
      }
    );

    // DOWNLOAD FILE
    const blob = new Blob([downloadFile.data]);
    saveAs(blob, report.fileName);

    Swal.close();
    Swal.fire('Downloaded!', 'Your file has been downloaded', 'success');
  } catch (error) {
    console.error(error);
    Swal.close();
    Swal.fire('Gagal', 'there is something wrong', 'error');
  }
};

const deleteFileReport = async (token, task, report) => {
  Swal.fire({
    title: 'Please wait...',
    text: 'Your delete is in progress',
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    },
  });

  await stomataAPI
    .delete(`/api/v1/tasks/delete-file/${report.fileId}`, {
      headers: {
        token,
      },
      data: {
        typeFile: report.type,
        taskId: task._id,
      },
    })
    .then(res => {
      Swal.close();
      Swal.fire('Deleted!', 'Report has been deleted', 'success');
    })
    .catch(err => {
      console.error(err);
      Swal.close();
      Swal.fire('Changes are not saved', 'there is something wrong', 'error');
    })
    .finally
    // refetch agronomist
    ();
};

export {uploadFileReport, downloadFileReport, deleteFileReport};
