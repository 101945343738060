/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import moment from 'moment';
import {useHistory, useParams} from 'react-router-dom';
import Swal from 'sweetalert2';
import {newAPI, stomataAPI} from '../../services/baseAPI';
import Icon from '../../components/Icon';
import useUserStore from '../../stores/Users';
import Card from '../../components/SimpleCard';
import LoadingView from '../../components/Loading';
import {HabibiTable, SelectColumnFilter} from '../../components/HabibiTable';

const labelTasks = [
  {value: 'mingguan', label: 'Mingguan'},
  {
    value: 'bulanan_bukukebun',
    label: 'Bulanan - Buku Kebun',
  },
  {value: 'bulanan_timeline', label: 'Bulanan - Timeline'},
  {
    value: 'bulanan_lapcuaca',
    label: 'Bulanan - Lap. Cuaca',
  },
  {
    value: 'bulanan_lapsistem',
    label: 'Bulanan - Lap. Sistem',
  },
  {
    value: 'bulanan_datasensor',
    label: 'Bulanan - Data Sensor',
  },
  {
    value: 'bulanan_forecasting',
    label: 'Bulanan - Ramalan Cuaca',
  },
  {value: 'musim_aut', label: 'Musim - AUT'},
  {value: 'musim_impact', label: 'Musim - Impact'},
  {value: 'musim_rekapan', label: 'Musim - Rekap Laporan'},
];

// React Component
// Header Component
const header = () => {
  const history = useHistory();

  return (
    <div>
      <div className="page-header d-print-none">
        <div className="row align-items-center">
          <button
            aria-label="Back Button"
            type="button"
            onClick={() => history.push('/farmer')}
            className="col-2"
            style={{
              marginLeft: '8px',
              marginRight: '10px',
              height: 40,
              width: 40,
              borderRadius: 20,
              backgroundColor: 'rgba(255, 0, 0, 0)',
              borderWidth: 0,
            }}>
            <Icon name="ArrowLeft" color="#333" size={20} />
          </button>
          <div className="col">
            <div className="page-pretitle">Lihat</div>
            <h2 className="page-title"> Pengguna</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

// Show User Component
const ShowUser = ({dataUser}) => (
  <div>
    <Card title="Data Diri">
      <div className="row row-decks row-cards">
        {/* First Name */}
        <div className="col-md-6">
          <label htmlFor="true" className="form-label">
            Nama Depan
          </label>
          <input
            disabled
            defaultValue={dataUser.first_name}
            name="first_name"
            type="text"
            className="form-control"
            placeholder="First Name"
          />
        </div>
        {/* Last Name */}
        <div className="col-md-6">
          <label htmlFor="true" className="form-label">
            Nama Belakang
          </label>
          <input
            disabled
            defaultValue={dataUser.last_name}
            name="last_name"
            type="text"
            className="form-control"
            placeholder="Last Name"
          />
        </div>

        {/* Email */}
        <div className="col-md-6">
          <label htmlFor="true" className="form-label">
            Email
          </label>
          <input
            disabled
            defaultValue={dataUser.email}
            name="email"
            type="email"
            className="form-control"
            placeholder="Email"
          />
        </div>

        {/* Phone */}
        <div className="col-md-6">
          <label htmlFor="true" className="form-label">
            Nomor Telepon
          </label>
          <input
            disabled
            defaultValue={dataUser.phone}
            name="phone"
            type="text"
            className="form-control"
            placeholder="Phone"
          />
        </div>
        {/* Birt Date */}
        <div className="col-md-6">
          <label htmlFor="true" className="form-label">
            Tanggal Lahir
          </label>
          <input
            disabled
            defaultValue={dataUser.birth_date}
            name="birth_date"
            type="date"
            className="form-control"
            placeholder="Birt Date"
            onChange={e => console.log(e.target.value)}
          />
        </div>

        {/* Gender */}
        <div className="col-md-6">
          <label htmlFor="true" className="form-label">
            Jenis Kelamin
          </label>
          <select
            disabled
            name="gender"
            defaultValue={dataUser.gender}
            className="form-select form-control">
            <option value="Male">Laki-Laki</option>
            <option value="Female">Perempuan</option>
          </select>
        </div>

        {/* City */}
        <div className="col-md-6">
          <label htmlFor="true" className="form-label">
            Kota
          </label>
          <input
            disabled
            defaultValue={dataUser.city}
            name="city"
            type="text"
            className="form-control"
            placeholder="City"
          />
        </div>

        {/* Status */}
        <div className="col-md-6">
          <label htmlFor="true" className="form-label">
            Status
          </label>
          <select
            disabled
            name="status"
            defaultValue={dataUser.status}
            className="form-select form-control">
            <option value="1">Admin</option>
            <option value="2">Owner</option>
            <option value="3">Petani</option>
            <option value="4">Penjual</option>
            <option value="6">Agronomist</option>
            <option value="7">Engineer & Mekanikal</option>
          </select>
        </div>
      </div>
    </Card>
  </div>
);

// Show Report Progress Component
const ShowTaskProgress = ({dataTasks}) => {
  const transformedData = [];

  dataTasks.forEach(farm => {
    const farmName = farm.farmInfo.name;
    const farmCode = farm.farmInfo.code;
    farm.tasks.forEach(task => {
      const taskName = task.name;
      const taskRemarks = task.remarks;
      const taskEndedAt = task.endedAt;
      task.todos.forEach(todo => {
        transformedData.push({
          farmName,
          farmCode,
          endedAt: taskEndedAt,
          taskCycle: `${taskName} (${taskRemarks})`,
          todoType: labelTasks.find(label => label.value === todo.typeFile)
            ?.label,
          target: todo.target,
          progress: todo.progress?.length || 0,
        });
      });
    });
  });

  const columns = [
    {
      Header: 'No',
      id: 'row',
      width: '5%',
      Cell: cellInfo => <div>{cellInfo.row.index + 1}</div>,
    },
    {
      Header: 'Nama Kebun',
      id: 'farm_name',
      accessor: 'farmName',
      Filter: SelectColumnFilter,
      filter: 'includes',
      sortType: 'basic',
      width: '25%',
      Cell: cellInfo => <div>{cellInfo.row.original.farmName}</div>,
    },
    {
      Header: 'Nama Siklus',
      id: 'task_cycle',
      accessor: 'taskCycle',
      Filter: SelectColumnFilter,
      filter: 'includes',
      sortType: 'basic',
      width: '25%',
      Cell: cellInfo => <div>{cellInfo.row.original.taskCycle}</div>,
    },
    {
      Header: 'Tipe Laporan',
      id: 'todo_type',
      accessor: 'todoType',
      Filter: SelectColumnFilter,
      filter: 'includes',
      sortType: 'basic',
      width: '20%',
      Cell: cellInfo => {
        let color;
        const {todoType} = cellInfo.row.original;

        switch (todoType) {
          case 'Mingguan':
            color = 'bg-blue';
            break;
          case 'Bulanan - Buku Kebun':
            color = 'bg-green';
            break;
          case 'Bulanan - Timeline':
            color = 'bg-yellow';
            break;
          case 'Bulanan - Lap. Cuaca':
            color = 'bg-orange';
            break;
          case 'Bulanan - Lap. Sistem':
            color = 'bg-purple';
            break;
          case 'Bulanan - Data Sensor':
            color = 'bg-pink';
            break;
          case 'Bulanan - Ramalan Cuaca':
            color = 'bg-cyan';
            break;
          case 'Musim - AUT':
            color = 'bg-red';
            break;
          case 'Musim - Impact':
            color = 'bg-gray';
            break;
          case 'Musim - Rekap Laporan':
            color = 'bg-indigo';
            break;
          default:
            color = 'bg-black';
            break;
        }

        return (
          <div>
            <span
              className={`badge ${color} m-1`}
              style={{width: '100%', fontSize: '12px'}}>
              <b>{todoType}</b>
            </span>
          </div>
        );
      },
    },
    {
      Header: 'Target Laporan',
      id: 'todo_target',
      accessor: 'todo_target',
      sortType: 'basic',
      width: '5%',
      Cell: cellInfo => <div>{cellInfo.row.original.target}</div>,
    },
    {
      Header: 'Progress Laporan',
      id: 'todo_progress',
      accessor: 'todo_progress',
      sortType: 'basic',
      width: '20%',
      Cell: cellInfo => {
        let color;
        let status;
        const {progress, target, endedAt} = cellInfo.row.original;
        const percentage = (progress / target) * 100;
        console.log('endedAt', endedAt);
        if (endedAt) {
          color = 'bg-gray';
          status = 'Sudah Ditutup';
        } else if (percentage === 0) {
          color = 'bg-red';
          status = 'Belum Dikerjakan';
        } else if (percentage > 0 && percentage < 100) {
          color = 'bg-yellow';
          status = 'Sedang Dikerjakan';
        } else if (percentage >= 100) {
          color = 'bg-green';
          status = 'Selesai';
        } else {
          color = 'bg-black';
          status = 'Error';
        }
        return (
          <div>
            <span className={`badge ${color}`} style={{width: '100%'}}>
              <b>
                {status} ({progress}/{target})
              </b>
            </span>
            {Number.isNaN(percentage) ? null : (
              <div className="progress mt-1" style={{height: 15}}>
                <div
                  className="progress-bar bg-green"
                  role="progressbar"
                  style={{width: `${percentage}%`}}
                  aria-valuenow={percentage}
                  aria-valuemin="0"
                  aria-valuemax="100">
                  <b>{Math.round(percentage)}%</b>
                </div>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="row row-deck row-cards">
      <div className="col-12">
        <Card styleBody={{padding: 0, paddingTop: 20}}>
          <HabibiTable
            title="Tugas Laporan"
            columns={columns}
            data={transformedData}
            formOne={1}
            formTwo={2}
            formThree={3}
            rowSize={[5, 20, 50]}
          />
        </Card>
      </div>
    </div>
  );
};

const FarmerShow = () => {
  const ADMIN = 1;
  const AGRONOMIST = 6;
  const {id} = useParams();
  const token = useUserStore(state => state.token);
  const userId = useUserStore(state => state.user.id);
  const userRole = useUserStore(state => state.user.status);
  const {setValue} = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [dataUser, setDataUser] = useState({});
  const [dataTasks, setDataTasks] = useState([]);

  const fetchUsers = async () => {
    setIsLoading(true);

    await newAPI
      .get(`/v3/users/${id}/show`, {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then(res => {
        const dataUsers = res.data[0];

        setValue(
          'birth_date',
          moment(dataUsers.birth_date).format('YYYY-MM-DD')
        );
        setValue('email', dataUsers.email);
        setValue('first_name', dataUsers.first_name);
        setValue('last_name', dataUsers.last_name);
        setValue('gender', dataUsers.gender);
        setValue('phone', dataUsers.phone);
        setValue('city', dataUsers.city);
        setValue('status', dataUsers.role_id);
        setDataUser({
          birth_date: moment(dataUsers.birth_date).format('YYYY-MM-DD'),
          email: dataUsers.email,
          first_name: dataUsers.first_name,
          last_name: dataUsers.last_name,
          gender: dataUsers.gender,
          phone: dataUsers.phone,
          city: dataUsers.city,
          status: dataUsers.role_id,
        });
      })
      .catch(error => console.log(`error ${error}`))
      .finally(() => setIsLoading(false));
  };

  const fetchTasks = async () => {
    try {
      setIsLoading(true);
      const bookmarkFarms = await newAPI.get(
        `/global/v1/farms/bookmark/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const codeFarms = bookmarkFarms.data.data.farms.map(
        farm => farm.code_farm
      );

      // get task for every codeFarms
      const tasks = await Promise.all(
        codeFarms.map(async codeFarm => {
          const task = await stomataAPI.get(
            `/api/v1/tasks/query/all?farmcode=${codeFarm}`,
            {
              headers: {token},
            }
          );

          return task.data.data;
        })
      );

      const flattenedTasks = tasks.flat();
      console.log('flattenedTasks', flattenedTasks);
      setDataTasks(flattenedTasks);
    } catch (error) {
      console.error('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(async () => {
    await fetchUsers();
    await fetchTasks();
  }, []);

  return (
    <div className="container-xl">
      {header()}

      <div className="row">
        {/* content */}
        {isLoading ? (
          <div className="col-md-12">
            <LoadingView />
          </div>
        ) : (
          <div className="col-md-12">
            <div className="mb-3">
              <ShowUser dataUser={dataUser} />
            </div>
            {dataUser.status === AGRONOMIST &&
            (userRole === ADMIN ||
              (userRole === AGRONOMIST && userId === id)) ? (
              <div className="mb-3">
                <ShowTaskProgress dataTasks={dataTasks} />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default FarmerShow;
