import {newAPI, oldAPI, stomataAPI} from '../services/baseAPI';

const getInventories = async () => {
  try {
    const response = await newAPI.get('/articles');
    return response.data;
  } catch (error) {
    console.error(`error ${error}`);
    throw error;
  }
};

const getStockIn = async () => {
  try {
    const response = await newAPI.get('/articles');
    return response.data;
  } catch (error) {
    console.error(`error ${error}`);
    throw error;
  }
};

export {getInventories, getStockIn};
