import React, {useEffect, useState, useCallback} from 'react';
import {Link} from 'react-router-dom';
import Select from 'react-select';
import moment from 'moment';
import useUserStore from '../../stores/Users';
import LoadingView from '../../components/Loading';
import Card from '../../components/SimpleCard';
import {HabibiTable} from '../../components/HabibiTable';
import {newAPI, newURI, oldAPI} from '../../services/baseAPI';
import Icon from '../../components/Icon';
import './Farms.css';

function Farm() {
  // State
  const ADMIN = 1;
  const OWNER = 2;
  const GUEST = 5;
  const AGRONOMIS = 6;
  const ENGINEER = 7;
  const token = useUserStore(state => state.token);
  const users = useUserStore(state => state.user);
  const [farmList, setFarmList] = useState([]);
  const [farmListActive, setFarmListActive] = useState([]);
  const [farmsOwner, setFarmsOwner] = useState([]);
  const [monitoring, setMonitoring] = useState([]);
  const [menuView, setMenuView] = useState('list');
  const [isLoading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  // LoadData
  const fetchAdmin = useCallback(async () => {
    try {
      const {data} = await newAPI.get('/v3/farms', {
        headers: {Authorization: `Bearer ${token}`},
      });

      // init state
      const dataDevice = [];
      const allData = data.data.last_data_device;

      // cleansing data
      const result = allData.reduce((r, a) => {
        const res = r;
        const tmpCollectingByFarm = {
          id: a.id,
          code_farm: a.code_farm,
          name: a.name,
          tgl_isi_pulsa: a.tgl_isi_pulsa,
          phone: a.phone,
          city: a.city,
        };
        switch (a.jenis_data) {
          case 'config':
            tmpCollectingByFarm.grow_datetime = a.datetime;
            break;
          case 'dose':
            tmpCollectingByFarm.dose_datetime = a.datetime;
            break;
          case 'aws':
            tmpCollectingByFarm.aws_datetime = a.datetime;
            break;
          case 'waterquality':
            tmpCollectingByFarm.hwq_datetime = a.datetime;
            break;
          case 'flowmeter':
            tmpCollectingByFarm.hfm_datetime = a.datetime;
            break;
          case 'zona':
            tmpCollectingByFarm.zone_datetime = [
              ...(res[a.code_farm]?.zone_datetime || []),
              a.datetime,
            ];
            break;
          default:
            break;
        }

        res[a.code_farm] = {
          ...(res[a.code_farm] || {}),
          ...tmpCollectingByFarm,
        };
        return res;
      }, {});

      Object.keys(result).map(key => dataDevice.push(result[key]));

      setMonitoring(dataDevice);

      // logic for agronomist and engineer role
      if (users.status === AGRONOMIS || users.status === ENGINEER) {
        const farmsTmp = [];
        const farmsActiveTmp = [];
        const allFarms = data.data.farms;
        const allBookmark = data.data.bookmarks;

        allFarms.forEach(item => {
          farmsTmp.push({
            value: item.id,
            label: item.name,
          });

          // check active farms
          const cekIdFarms = allBookmark.find(
            itemFarms => itemFarms.farms_id === item.id
          );
          if (cekIdFarms) {
            farmsActiveTmp.push({
              value: item.id,
              label: item.name,
            });
          }
        });

        setFarmList(farmsTmp);
        setFarmListActive(farmsActiveTmp);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  const fetchOwner = useCallback(async () => {
    await newAPI
      .get('/v3/farms', {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        setFarmsOwner(res.data.last_data_device);
        setLoading(false);
      })
      .catch(error => {
        console.log(`error ${error}`);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (
      users.status === ADMIN ||
      users.status === AGRONOMIS ||
      users.status === ENGINEER
    ) {
      fetchAdmin();
    } else if (users.status === OWNER) {
      fetchOwner();
    } else {
      fetchOwner();
    }
  }, [users, token]);

  useEffect(() => {
    let timer;
    if (
      users.status === ADMIN ||
      users.status === AGRONOMIS ||
      users.status === ENGINEER
    ) {
      timer = setTimeout(() => {
        if (count === 60) {
          fetchAdmin();
          setCount(0);
        } else {
          setCount(count + 1);
        }
      }, 1000);
    }

    // will unmount
    return () => {
      if (
        users.status === ADMIN ||
        users.status === AGRONOMIS ||
        users.status === ENGINEER
      ) {
        clearTimeout(timer);
      }
    };
  });

  // Controller
  const checkStatusDevice = dateTime => {
    const refDate = new Date(dateTime);
    const nowDate = new Date();
    const delta = parseInt((nowDate.getTime() - refDate) / 1000, 10);
    return delta;
  };

  const statusGroundColor = datetime => {
    let gsColor;
    const arrZone = [];

    if (datetime) {
      datetime.map(item => arrZone.push(checkStatusDevice(item)));
    }

    if (arrZone.length === 0) {
      gsColor = '#CCCCCC';
    } else if (arrZone.filter(item => item >= 300).length >= arrZone.length) {
      gsColor = '#F53E4E';
    } else {
      gsColor = '#57B229';
    }

    return gsColor;
  };

  const contentDateTime = dt => {
    let datetime = null;

    if (dt?.grow_datetime !== undefined) {
      datetime = dt.grow_datetime;
    } else {
      datetime = dt[Object.keys(dt).find(key => key.includes('datetime'))];
    }

    if (datetime) {
      let color = '';
      if (checkStatusDevice(datetime) >= 300) {
        color = 'red';
      } else {
        color = 'green';
      }
      return (
        <div>
          <p className="list-header-one">
            {moment(datetime).format('DD-MM-YYYY HH:mm:ss')}
          </p>
          <p className="list-subheader" style={{color}}>
            {checkStatusDevice(datetime)} detik yang lalu
          </p>
        </div>
      );
    }

    return <span>-</span>;
  };

  const contentFarmName = (id, name, codeFarm, datetime) => {
    if (id && name && codeFarm) {
      return (
        <Link
          to={`/farm/${id}/detail`}
          className="cursor-pointer text-decoration-none">
          <p className="list-header-one">
            {name} <span style={{color: '#777'}}>({codeFarm})</span>
          </p>
          <p
            className="list-subheader"
            style={{color: '#777', cursor: 'pointer'}}>
            Klik untuk melihat detail kebun
          </p>
        </Link>
      );
    }

    return <span>-</span>;
  };

  const handleStoreBookmark = async params => {
    setLoading(true);

    const valuesSql = [];
    farmListActive.forEach(item => {
      valuesSql.push(`(NULL, ${users.id}, ${item.value})`);
    });

    try {
      const store = await newAPI.post(
        '/v3/farms/bookmarks',
        {
          farmlist: valuesSql.toString(),
        },
        {
          headers: {Authorization: `Bearer ${token}`},
        }
      );
      fetchAdmin();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // View
  const headerView = () => (
    <>
      <div className="page-header">
        <div className="row align-items-center">
          {/* Title */}
          <div className="col">
            <div className="page-pretitle">Menu</div>
            <h2 className="page-title">Kelola Kebun</h2>
          </div>

          {/* Actions Button */}
          <div className="col-auto ml-auto d-print-none">
            {users.status === ADMIN ? (
              <>
                {/* menu view */}
                <button
                  type="button"
                  className="btn btn-gray"
                  onClick={() =>
                    menuView === 'list'
                      ? setMenuView('grid')
                      : setMenuView('list')
                  }>
                  {menuView === 'list' ? (
                    <div className="d-flex align-items-center">
                      <Icon name="Grid" size={16} style={{marginRight: 5}} />{' '}
                      Grid
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <Icon name="List" size={16} style={{marginRight: 5}} />{' '}
                      List
                    </div>
                  )}
                </button>

                {/* tambah kebun */}
                <Link to="/farm/create">
                  <button
                    type="button"
                    className="btn btn-gray"
                    style={{marginLeft: 10}}
                    onClick={() => console.log('Kebun')}>
                    <div className="d-flex align-items-center">
                      <Icon
                        name="PlusCircle"
                        size={16}
                        style={{marginRight: 5}}
                      />{' '}
                      <span>Tambah Kebun</span>
                    </div>
                  </button>
                </Link>
              </>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
    </>
  );

  const ownerView = () => (
    <div className="row row-deck row-cards">
      {farmsOwner.map((item, index) => (
        <div key={item.id} className="col-md-4">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">{item.name}</h3>
            </div>
            <div className="card-body">
              {/* farm description */}
              <div className="row mb-3">
                <div className="col-md-4">
                  <img
                    src={`${newURI}/farms/${
                      item.img ? item.img : '1602827731-pp (1).jfif.jfif'
                    }`}
                    className="rounded-circle d-block hg-img-circle"
                    alt="Logo Kebun"
                  />
                </div>
                <div className="col-md-8 my-auto">
                  <p className="d-flex m-0 mt-1 p-0">
                    No. Modem:{' '}
                    <span className="ms-auto">
                      {item.phone ? item.phone : '-'}
                    </span>
                  </p>
                  <p className="d-flex m-0 mt-1 p-0">
                    Tgl isi pulsa:{' '}
                    <span className="ms-auto">
                      {item.tgl_isi_pulsa ? item.tgl_isi_pulsa : '-'}
                    </span>
                  </p>
                  <p className="d-flex m-0 mt-1 p-0">
                    Lokasi: <span className="ms-auto strong">{item.city}</span>
                  </p>
                </div>
              </div>

              {/* farm view */}
              <Link
                to={`/farm/${item.farms_id}/detail`}
                className="btn btn-active w-100 btn-block">
                Lihat Kebun
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const adminListView = () => {
    const columns = [
      {
        Header: 'No',
        id: 'row',
        width: '5%',
        Cell: cellInfo => (
          <div className="text-center">{cellInfo.row.index + 1}</div>
        ),
      },
      {
        Header: 'Date',
        id: 'datetime',
        accessor: 'datetime',
        width: '15%',
        Cell: cellInfo => contentDateTime(cellInfo.row.original),
      },
      {
        Header: 'Nama Kebun',
        accessor: 'name',
        width: '40%',
        Cell: cellInfo =>
          contentFarmName(
            cellInfo.row.original.id,
            cellInfo.row.original.name,
            cellInfo.row.original.code_farm
          ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: '40%',
        Cell: cellInfo => {
          let growColor = '';
          let awsColor = '';
          let doseColor = '';
          let hwqColor = '';
          let hfmColor = '';

          if (cellInfo.row.original?.grow_datetime) {
            if (checkStatusDevice(cellInfo.row.original.grow_datetime) >= 300) {
              growColor = '#F53E4E';
            } else {
              growColor = '#57B229';
            }
          } else {
            growColor = '#CCCCCC';
          }

          if (cellInfo.row.original?.aws_datetime) {
            if (checkStatusDevice(cellInfo.row.original.aws_datetime) >= 300) {
              awsColor = '#F53E4E';
            } else {
              awsColor = '#57B229';
            }
          } else {
            awsColor = '#CCCCCC';
          }

          if (cellInfo.row.original?.dose_datetime) {
            if (checkStatusDevice(cellInfo.row.original.dose_datetime) >= 300) {
              doseColor = '#F53E4E';
            } else {
              doseColor = '#57B229';
            }
          } else {
            doseColor = '#CCCCCC';
          }

          if (cellInfo.row.original?.hwq_datetime) {
            if (checkStatusDevice(cellInfo.row.original.hwq_datetime) >= 300) {
              hwqColor = '#F53E4E';
            } else {
              hwqColor = '#57B229';
            }
          } else {
            hwqColor = '#CCCCCC';
          }

          if (cellInfo.row.original?.hfm_datetime) {
            if (checkStatusDevice(cellInfo.row.original.hfm_datetime) >= 300) {
              hfmColor = '#F53E4E';
            } else {
              hfmColor = '#57B229';
            }
          } else {
            hfmColor = '#CCCCCC';
          }

          return (
            <div className="row justify-content-between">
              {/* Grow Status */}
              <div className="text-muted text-center col-md-2">
                <Icon
                  name="Power"
                  size={30}
                  style={{
                    marginTop: '-3px',
                    margin: 0,
                    color: '#fff',
                    background: growColor,
                    borderRadius: 30,
                    padding: 7,
                  }}
                />
                <p className="list-header-two">Grow</p>
                <p style={{margin: 0, fontSize: 11, fontWeight: 600}}>
                  {cellInfo.row.original?.grow_datetime
                    ? moment(cellInfo.row.original.grow_datetime).format(
                        'DD/MM/YYYY HH:mm:ss'
                      )
                    : '-'}
                </p>
              </div>

              {/* GS Status */}
              <div className="text-muted text-center col-md-2">
                <Icon
                  name="Activity"
                  size={30}
                  style={{
                    marginTop: '-3px',
                    margin: 0,
                    color: '#fff',
                    background: statusGroundColor(
                      cellInfo.row.original.zone_datetime
                    ),
                    borderRadius: 30,
                    padding: 7,
                  }}
                />
                <p className="list-header-two" style={{marginBottom: -2}}>
                  GS (Zona)
                </p>
                {cellInfo.row.original?.zone_datetime &&
                cellInfo.row.original?.zone_datetime.length > 0 ? (
                  cellInfo.row.original?.zone_datetime.map((item, index) => (
                    <p
                      style={{
                        display: 'inline-block',
                        margin: 0,
                        marginRight: 5,
                        fontSize: 13,
                        fontWeight: 600,
                        color:
                          checkStatusDevice(item) >= 300
                            ? '#F53E4E'
                            : '#57B229',
                      }}>
                      {index + 1}
                    </p>
                  ))
                ) : (
                  <p style={{margin: 0, fontSize: 11, fontWeight: 600}}>-</p>
                )}
              </div>

              {/* Dose Status */}
              <div className="text-muted text-center col-md-2">
                <Icon
                  name="CloudSnow"
                  size={30}
                  style={{
                    marginTop: '-3px',
                    margin: 0,
                    color: '#fff',
                    background: doseColor,
                    borderRadius: 30,
                    padding: 7,
                  }}
                />
                <p className="list-header-two">Dose</p>
                <p style={{margin: 0, fontSize: 11, fontWeight: 600}}>
                  {cellInfo.row.original?.dose_datetime
                    ? moment(cellInfo.row.original.dose_datetime).format(
                        'DD/MM/YYYY HH:mm:ss'
                      )
                    : '-'}
                </p>
              </div>

              {/* AWS Status */}
              <div className="text-muted text-center col-md-2">
                <Icon
                  name="Cloud"
                  size={30}
                  style={{
                    marginTop: '-3px',
                    margin: 0,
                    color: '#fff',
                    background: awsColor,
                    borderRadius: 30,
                    padding: 7,
                  }}
                />
                <p className="list-header-two">AWS</p>
                <p style={{margin: 0, fontSize: 11, fontWeight: 600}}>
                  {cellInfo.row.original?.aws_datetime
                    ? moment(cellInfo.row.original.aws_datetime).format(
                        'DD/MM/YYYY HH:mm:ss'
                      )
                    : '-'}
                </p>
              </div>

              {/* Water Quality Status */}
              <div className="text-muted text-center col-md-2">
                <Icon
                  name="Droplet"
                  size={30}
                  style={{
                    marginTop: '-3px',
                    margin: 0,
                    color: '#fff',
                    background: hwqColor,
                    borderRadius: 30,
                    padding: 7,
                  }}
                />
                <p className="list-header-two">HWQ</p>
                <p style={{margin: 0, fontSize: 11, fontWeight: 600}}>
                  {cellInfo.row.original?.hwq_datetime
                    ? moment(cellInfo.row.original.hwq_datetime).format(
                        'DD/MM/YYYY HH:mm:ss'
                      )
                    : '-'}
                </p>
              </div>

              {/* Flow Meter Status */}
              <div className="text-muted text-center col-md-2">
                <Icon
                  name="Droplet"
                  size={30}
                  style={{
                    marginTop: '-3px',
                    margin: 0,
                    color: '#fff',
                    background: hfmColor,
                    borderRadius: 30,
                    padding: 7,
                  }}
                />
                <p className="list-header-two">HFM</p>
                <p style={{margin: 0, fontSize: 11, fontWeight: 600}}>
                  {cellInfo.row.original?.hfm_datetime
                    ? moment(cellInfo.row.original.hfm_datetime).format(
                        'DD/MM/YYYY HH:mm:ss'
                      )
                    : '-'}
                </p>
              </div>
            </div>
          );
        },
      },
    ];

    return (
      <div className="row row-deck row-cards">
        <div className="col-md-12">
          <Card styleBody={{padding: 0, paddingTop: 20}}>
            <HabibiTable
              title="Device"
              columns={columns}
              data={monitoring}
              formOne={null}
              formTwo={null}
              formThree={null}
              rowSize={[50, 100, 150]}
            />
          </Card>
        </div>

        {/* form add farm */}
        {/* {users.status === AGRONOMIS || users.status === ENGINEER ? (
          <div className="col-md-12">
            <Card title="Tambah Daftar Kebun">
              <div className="row">
                <div className="col-md-8">
                  <Select
                    defaultValue={farmListActive}
                    options={farmList}
                    isMulti
                    placeholder="Tambah kebun kedalam bookmark"
                    onChange={e => setFarmListActive(e)}
                  />
                </div>
                <div className="col-md-4 ">
                  <button
                    type="button"
                    className="w-100 border-2 btn btn-primary"
                    onClick={handleStoreBookmark}>
                    Tambah/Ubah Bookmark
                  </button>
                </div>
              </div>
            </Card>
          </div>
        ) : (
          <></>
        )} */}
      </div>
    );
  };

  const adminGridView = () => (
    <div className="row row-deck row-cards">
      {monitoring.map((item, index) => (
        <div key={item.id} className="col-md-3">
          <Card>
            <>
              {/* title */}
              {contentFarmName(item.id, item.name, item.code_farm)}

              {/* Title */}
              <div className="d-flex my-4 justify-content-center">
                <hr className="my-auto flex-grow-1" />
                <div className="px-4">Kondisi Perangkat</div>
                <hr className="my-auto flex-grow-1" />
              </div>

              {/* body */}
              <div className="row row-cards">
                {/* Tanggal isi pulsa */}
                <div className="text-muted text-center col-4 col-md-6 mb-3">
                  <Icon
                    name="Clock"
                    size={30}
                    style={{
                      marginTop: '-3px',
                      margin: 0,
                      color: '#fff',
                      background: '#CCCCCC',
                      borderRadius: 30,
                      padding: 7,
                    }}
                  />
                  <p className="list-header-two">Pulsa</p>
                  <p style={{margin: 0, fontSize: 11, fontWeight: 600}}>
                    {item.tgl_isi_pulsa == null ? '-' : item.tgl_isi_pulsa}
                  </p>
                </div>

                {/* Time Status */}
                <div className="text-muted text-center col-4 col-md-6 mb-3">
                  <Icon
                    name="Clock"
                    size={30}
                    style={{
                      marginTop: '-3px',
                      margin: 0,
                      color: '#fff',
                      background: '#CCCCCC',
                      borderRadius: 30,
                      padding: 7,
                    }}
                  />
                  <p className="list-header-two">Time</p>
                  <p style={{margin: 0, fontSize: 11, fontWeight: 600}}>
                    {item.rtc_datetime === null
                      ? 'Tidak ada RTC'
                      : item.rtc_datetime}
                  </p>
                </div>

                {/* AWS Status */}
                <div className="text-muted text-center col-4 col-md-6 mb-3">
                  <Icon
                    name="Cloud"
                    size={30}
                    style={{
                      marginTop: '-3px',
                      margin: 0,
                      color: '#fff',
                      background: '#CCCCCC',
                      borderRadius: 30,
                      padding: 7,
                    }}
                  />
                  <p className="list-header-two">AWS</p>
                  <p style={{margin: 0, fontSize: 11, fontWeight: 600}}>
                    {item.aws_datetime === null
                      ? 'Tidak ada AWS'
                      : item.aws_datetime}
                  </p>
                </div>

                {/* GS Status */}
                <div className="text-muted text-center col-4 col-md-6 mb-3">
                  <Icon
                    name="Activity"
                    size={30}
                    style={{
                      marginTop: '-3px',
                      margin: 0,
                      color: '#fff',
                      background: '#CCCCCC',
                      borderRadius: 30,
                      padding: 7,
                    }}
                  />
                  <p className="list-header-two" style={{marginBottom: -2}}>
                    GS (Zona)
                  </p>
                  {item.jml_zona >= 1 ? (
                    <p
                      style={{
                        display: 'inline-block',
                        margin: 0,
                        marginRight: 5,
                        fontSize: 13,
                        fontWeight: 600,
                        color: '#CCCCCC',
                      }}>
                      1
                    </p>
                  ) : (
                    ''
                  )}
                  {item.jml_zona >= 2 ? (
                    <p
                      style={{
                        display: 'inline-block',
                        margin: 0,
                        marginRight: 5,
                        fontSize: 13,
                        fontWeight: 600,
                        color: '#CCCCCC',
                      }}>
                      2
                    </p>
                  ) : (
                    ''
                  )}
                  {item.jml_zona >= 3 ? (
                    <p
                      style={{
                        display: 'inline-block',
                        margin: 0,
                        marginRight: 5,
                        fontSize: 13,
                        fontWeight: 600,
                        color: '#CCCCCC',
                      }}>
                      3
                    </p>
                  ) : (
                    ''
                  )}
                  {item.jml_zona >= 4 ? (
                    <p
                      style={{
                        display: 'inline-block',
                        margin: 0,
                        fontSize: 13,
                        fontWeight: 600,
                        color: '#CCCCCC',
                      }}>
                      4
                    </p>
                  ) : (
                    ''
                  )}
                </div>

                {/* Device Status */}
                <div className="text-muted text-center col-4 col-md-6 mb-3">
                  <Icon
                    name="Power"
                    size={30}
                    style={{
                      marginTop: '-3px',
                      margin: 0,
                      color: '#fff',
                      background: '#CCCCCC',
                      borderRadius: 30,
                      padding: 7,
                    }}
                  />
                  <p className="list-header-two">Device</p>
                  <p
                    style={{
                      margin: 0,
                      fontSize: 13,
                      fontWeight: 600,
                      color: '#CCCCCC',
                    }}>
                    {checkStatusDevice(item.datetime) >= 300 ? 'OFF' : 'ON'}
                  </p>
                </div>

                {/* empty col */}
                <div className="text-muted text-center col-4 col-md-6 mb-3" />

                {/* datetime */}
                <div className="mt-2 mb-1 text-center">
                  {contentDateTime(item.datetime)}
                  {/* <div>
                    <small>
                      <strong>{item.datetime}</strong>
                    </small>
                  </div>
                  <div>
                    <small>{checkStatusDevice(item.datetime)} detik yang lalu</small>
                  </div> */}
                </div>
              </div>
            </>
          </Card>
        </div>
      ))}
    </div>
  );

  const adminView = () => (
    <>{menuView === 'list' ? adminListView() : adminGridView()}</>
  );

  const handleView = () => {
    switch (users.status) {
      case ADMIN:
      case AGRONOMIS:
      case ENGINEER:
        return adminView();

      case OWNER:
        return ownerView();

      default:
        return ownerView();
    }
  };

  return (
    <div className="container">
      {headerView()}

      {/* content */}
      {isLoading ? <LoadingView /> : handleView()}
    </div>
  );
}

export default Farm;
