import React, {useState, useEffect} from 'react';
import idx from 'idx';
import moment from 'moment';
import PropTypes from 'prop-types';
import {newAPI} from '../../../services/baseAPI';
import Icon from '../../../components/Icon';
import Card from '../../../components/SimpleCard';
import LoadingView from '../../../components/Loading';

function Report(props) {
  const [isLoading, setLoading] = useState(false);
  const [reportList, setReportList] = useState([]);

  const fetchReport = async () => {
    setLoading(true);

    await newAPI
      .post('/v3/reports', {
        farmCode: props.farmCode,
      })
      .then(res => {
        setReportList(res.data.data.reportList);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchReport();
  }, []);

  const reportView = params => (
    <>
      <div className="col-md-9">
        <div className="row row-deck row-cards">
          {/*  Current */}
          {reportList.map(item => {
            const text = item.desc.split('\n').map(str => (
              <div>
                {str}
                <br />
              </div>
            ));

            return (
              <div className="col-6">
                <Card title={item.title} className="mx-auto">
                  <p>{text}</p>
                </Card>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );

  return (
    <>
      {/* content */}
      {isLoading ? <LoadingView /> : reportView()}
    </>
  );
}

Report.propTypes = {
  farmCode: PropTypes.arrayOf.isRequired,
};

export default Report;
