import React, {useState, useEffect, useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {Link, useHistory, useParams} from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';
import Icon from '../../components/Icon';
import useUserStore from '../../stores/Users';
import Card from '../../components/SimpleCard';
import LoadingView from '../../components/Loading';
import {newAPI, oldAPI} from '../../services/baseAPI';

function KelanaEditFarm() {
  // State
  const ADMIN = 1;
  const OWNER = 2;
  const token = useUserStore(state => state.token);
  const users = useUserStore(state => state.user);
  const {register, errors, handleSubmit, setValue} = useForm();
  const history = useHistory();
  const {id} = useParams();

  const [data, setData] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [bookmarkUsers, setBookmarkUsers] = useState([]);
  const [addbookmarkUsers, setAddBookmarkUsers] = useState([]);
  const [chbookmarkUsers, setChBookmarkUsers] = useState([]);

  const getDataPlantsBatch = useCallback(
    kelanaid =>
      newAPI
        .get(`/kelana/${kelanaid}/plants`)
        .then(res => res.data)
        .catch(error => console.log(`error ${error}`)),
    []
  );

  const fetchFarm = useCallback(async () => {
    setIsLoading(true);
  }, []);

  const fetchHub = useCallback(async () => {
    await newAPI
      .get(`/kelana/${id}/bookmarks/farms`)
      .then(res => {
        setBookmarkUsers(res.data);
      })
      .catch(err => {
        console.log(`error ${err}`);
      });
  }, []);

  const deleteHub = async bookmarkId => {
    setIsLoading(true);
    await newAPI
      .delete(`/kelana/${bookmarkId}/bookmarks/farms`)
      .then(() => {
        fetchHub();
      })
      .catch(err => {
        console.log(`error ${err}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const fetchBookmark = async () => {
    await newAPI
      .get(`/v3/users`, {headers: {Authorization: `Bearer ${token}`}})

      .then(res => {
        setAddBookmarkUsers(res.data.data);
      })
      .catch(error => {
        console.log(`error ${error}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const AddHub = () => {
    setIsLoading(true);
    const body = new FormData();
    body.append('status', 'aktif');
    body.append('user_id', chbookmarkUsers.value);
    body.append('kelana_id', id);
    oldAPI
      .post('/v2/boorkmarksKelana', body, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(async res => {
        await fetchHub(res.data.data);
        console.log(`berhasil upload ${JSON.stringify(res)}`);
        Swal.fire('Saved!', 'User berhasil ditambahkan', 'success');
      })
      .catch(error => {
        console.log(`error ${error}`);
        Swal.fire('Changes are not saved', 'there is something wrong', 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(async () => {
    await fetchFarm();
    await fetchHub();
    await fetchBookmark();
  }, []);

  const editFarm = async params => {
    setIsLoading(true);
    const body = {
      farm_name: params.farm_name,
      location: params.loc,
      city: params.city,
      status_farm: params.status_farm,
      area_farm: params.area_farm,
      type_soil: params.type_soil,
    };
  };
  // HubunganAkun

  const header = () => (
    <>
      <div className="page-header d-print-none">
        <div className="row align-items-center">
          <button
            aria-label="Back Button"
            type="button"
            onClick={() => history.push('/kelana')}
            className="col-2"
            style={{
              marginLeft: '8px',
              marginRight: '10px',
              height: 40,
              width: 40,
              borderRadius: 20,
              backgroundColor: 'rgba(255, 0, 0, 0)',
              borderWidth: 0,
            }}>
            <Icon name="ArrowLeft" color="#333" size={20} />
          </button>
          <div className="col">
            <div className="page-pretitle">Edit</div>
            <h2 className="page-title">Kebun Kelana</h2>
          </div>
        </div>
      </div>
    </>
  );
  const editView = () => (
    <>
      <div className="row row-deck row-cards">
        <Card title="Form Edit Kebun Kelana">
          <form onSubmit={handleSubmit(editFarm)}>
            <div className="row row-decks row-cards">
              {/* nama kebun */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Nama Kebun
                </label>
                <input
                  ref={register({required: true})}
                  name="farm_name"
                  type="text"
                  className="form-control"
                  placeholder="Masukan nama kebun"
                  defaultValue={data.farm_name}
                />
                {errors.farm_name && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Nama Kebun wajib diisi
                  </div>
                )}
              </div>

              {/* Area */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Luas Lahan (ha)
                </label>
                <input
                  ref={register({required: true})}
                  name="area_farm"
                  type="number"
                  className="form-control"
                  placeholder="Masukan Luas lahan"
                  step={0.01}
                  min={0}
                  defaultValue={data.area_farm}
                />
                {errors.area_farm && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Area lahan wajib diisi
                  </div>
                )}
              </div>

              {/* Lokasi */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Lokasi
                </label>
                <input
                  ref={register({required: true})}
                  name="loc"
                  type="text"
                  className="form-control"
                  placeholder="Masukan Lokasi"
                  defaultValue={data.location}
                />
                {errors.loc && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Lokasi wajib diisi
                  </div>
                )}
              </div>

              {/* kota */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Kota
                </label>
                <input
                  ref={register({required: true})}
                  name="city"
                  type="text"
                  className="form-control"
                  placeholder="Masukan Lokasi"
                  defaultValue={data.city}
                />
                {errors.city && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Kota wajib diisi
                  </div>
                )}
              </div>

              {/* jenis tanah */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Jenis Tanah
                </label>
                <select
                  name="type_soil"
                  ref={register({required: true})}
                  className="form-select form-control"
                  defaultValue={data.type_soil}>
                  <option value="Umum">Umum</option>
                  <option value="Pasir">Pasir</option>
                  <option value="Lempung">Lempung</option>
                  <option value="Liat">Liat</option>
                </select>
                {errors.type_soil && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Jenis tanah wajib diisi
                  </div>
                )}
              </div>
              {/* Status */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Status
                </label>
                <select
                  name="status_farm"
                  ref={register({required: true})}
                  className="form-select form-control"
                  defaultValue={data.status_farm}>
                  <option value="aktif">Aktif</option>
                  <option value="hapus">Non-Aktif</option>
                </select>
                {errors.type_soil && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Jenis tanah wajib diisi
                  </div>
                )}
              </div>

              {/* tombol */}
              <div className="col-md-12">
                <input
                  className="btn w-100 btn-primary px-5"
                  type="submit"
                  value="Edit Kebun"
                />
              </div>
            </div>
          </form>
        </Card>
        <Card title="Hubungan Kebun & Akun">
          <div className="row row-decks row-cards col-12">
            {/* Data user yang berhubunangan */}
            <div className="col-9">
              <label className="col-1" />
              <label className="col-md-4">Nama User</label>
            </div>
            {bookmarkUsers &&
              bookmarkUsers.map((e, index) => (
                <div
                  className=" col-9 col-sm-9 col-md-9 d-flex"
                  key={e.bookmark_id}>
                  <p className=" col-1">{index + 1}</p>
                  <p className=" col-md-8 col-sm-8 col-8"> {e.first_name}</p>
                  <div className=" col-4 ">
                    <button
                      type="button"
                      className="btn btn-danger col-8 col-sm-6 col-md-6"
                      onClick={() => {
                        deleteHub(e.bookmark_id);
                      }}>
                      Hapus
                    </button>
                  </div>
                </div>
              ))}
            {/* tambah user */}
            <div className=" col-9 col-sm-9 col-md-9 d-flex justify-content-between">
              <Select
                className="col-7 col-sm-7 col-md-7"
                options={addbookmarkUsers.map(e => ({
                  label: e.first_name,
                  value: e.id,
                }))}
                onChange={setChBookmarkUsers}
              />

              <div className="col-3 col-sm-3 col-md-3 col-xxl-3">
                <button
                  type="button"
                  className="btn btn-primary  col-11 col-sm-8 col-md-8"
                  onClick={AddHub}>
                  Tambah User
                </button>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );

  return (
    <div className="container-xl">
      {header()}

      <div className="row">
        {/* content */}
        {isLoading ? (
          <div className="col-md-12">
            <LoadingView />
          </div>
        ) : (
          editView()
        )}
      </div>
    </div>
  );
}

export default KelanaEditFarm;
