/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
import React, {useState, useCallback, useEffect} from 'react';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';

import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

import Modal from 'react-modal';
import {useHistory} from 'react-router-dom';

import useUserStore from '../../stores/Users';
import LoadingView from '../../components/Loading';
// import Card from '../../components/SimpleCard';
import CardDescMini from '../../components/CardDescMini';
import Icon from '../../components/Icon';
import './Dashboard.css';
import {newURI} from '../../services/baseAPI';

function Dashboard() {
  // State
  const ADMIN = 1;
  const OWNER = 2;
  const GUEST = 5;
  const EMPLOYEES = 6 || 7;
  // const token = useUserStore((state) => state.token);
  const users = useUserStore(state => state.user);
  const navigate = useHistory();

  const [gmaps, setGmaps] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [nameFarm, setNameFarm] = useState('');
  const [imageFarm, setImageFarm] = useState('');
  const [menu, setMenu] = useState('general');
  const [progress, setProgress] = useState([]);
  const [progressCalc, setProgressCalc] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [generalInfo, setGeneralInfo] = useState({
    allFarms: 0,
    allSumArea: 0,
    allSumLength: 0,
    allComodities: 0,
    allFarmers: 0,
    lastUserData: 0,
    lastFarmData: 0,
    lastAreaData: 0,
    lastLengthData: 0,
  });
  const [seriesArea, setSeriesArea] = useState([
    {
      name: 'Area',
      data: [
        {
          x: 'Jan',
          y: 0,
        },
      ],
    },
  ]);
  const [seriesFarm, setSeriesFarm] = useState([
    {
      name: 'Farms',
      data: [
        {
          x: 'Jan',
          y: 0,
        },
      ],
    },
  ]);
  const [seriesUser, setSeriesUser] = useState([
    {
      name: 'Petani',
      data: [
        {
          x: 'Jan',
          y: 0,
        },
      ],
    },
  ]);
  const [optionsFarm] = useState({
    chart: {
      type: 'area',
      fontFamily: 'inherit',
      height: 40.0,
      sparkline: {
        enabled: true,
      },
      animations: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 0.16,
      type: 'solid',
    },
    stroke: {
      width: 2,
      lineCap: 'round',
      curve: 'smooth',
    },
    xaxis: {
      labels: {
        padding: 0,
      },
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        show: false,
      },
      type: 'categories',
    },
    colors: ['#fab104'],
  });
  const [optionsUser] = useState({
    chart: {
      type: 'area',
      fontFamily: 'inherit',
      height: 40.0,
      sparkline: {
        enabled: true,
      },
      animations: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 0.16,
      type: 'solid',
    },
    stroke: {
      width: 2,
      lineCap: 'round',
      curve: 'smooth',
    },
    xaxis: {
      labels: {
        padding: 0,
      },
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        show: false,
      },
      type: 'categories',
    },
    colors: ['#00b4b9'],
  });
  const [options] = useState({
    chart: {
      type: 'area',
      fontFamily: 'inherit',
      height: 40.0,
      sparkline: {
        enabled: true,
      },
      animations: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 0.16,
      type: 'solid',
    },
    stroke: {
      width: 2,
      lineCap: 'round',
      curve: 'smooth',
    },
    xaxis: {
      labels: {
        padding: 0,
      },
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        show: false,
      },
      type: 'categories',
    },
    colors: ['#206bc4'],
  });
  const [latlng, setLatLng] = useState({
    lat: 0.7893,
    lng: 113.9213,
  });
  const [latlng2, setLatLng2] = useState([]);
  const optionss = {
    fillColor: 'red',
    fillOpacity: 0.1,
    strokeColor: 'red',
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: true,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
  };
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  // const {isLoaded, loadError} = useLoadScript({
  //   googleMapsApiKey: 'AIzaSyD-FqHubInKsX5Culh_eyNN0NRQbB9ePFs',
  // });

  // LoadData
  const fetchGeneral = useCallback(async () => {
    setLoading(true);

    await axios
      .post(`${newURI}/v3/dashboard`, {
        status: 1,
        // farmCode: 'KAB_BANDUNG_1',
      })
      .then(res => {
        const info = res.data.data;
        const areaKebun = res.data.data.mapsBound;
        // const arrFarm = [];

        // areaKebun.forEach((item, index) => {
        //   // kebun
        //   for (let i = 0; i < item.zone.length; i += 1) {
        //     // zona
        //     const hslSplitLat = item.zone[i].latitude_area.split(':');
        //     const hslSplitLng = item.zone[i].longitude_area.split(':');

        //     const arrArea = [];
        //     for (let j = 0; j < hslSplitLat.length; j += 1) {
        //       // area
        //       arrArea.push({
        //         lng: parseFloat(hslSplitLng[j]),
        //         lat: parseFloat(hslSplitLat[j]),
        //       });
        //     }
        //     arrFarm.push(arrArea);
        //   }
        // });
        // setGmaps(arrFarm);

        const arrMarker = [];
        areaKebun.forEach(item => {
          arrMarker.push({
            name: item.name,
            image: item.image,
            lat: parseFloat(item.latitude_point),
            lng: parseFloat(item.longitude_point),
          });
        });
        setLatLng2(arrMarker);

        // Set info global warehouse
        setGeneralInfo({
          allFarms: info.allFarms,
          allSumArea: info.allAreas.sum_area,
          allSumLength: info.allAreas.sum_length,
          allComodities: info.allComodities,
          allFarmers: info.allFarmers,

          lastUserData:
            info.usersPerMonth[0] === undefined ? '-' : info.usersPerMonth[0].y,
          lastFarmData:
            info.farmsPerMonth[0] === undefined ? '-' : info.farmsPerMonth[0].y,
          lastAreaData:
            info.areasPerMonth[0][0] === undefined
              ? '-'
              : info.areasPerMonth[0][0].y,
          lastLengthData:
            info.areasPerMonth[1][0] === undefined
              ? '-'
              : info.areasPerMonth[1][0].y,
        });

        // Reverse & Set data graphic
        setSeriesArea([
          {
            name: 'Area',
            data: info.areasPerMonth[0].reverse(),
          },
        ]);

        setSeriesFarm([
          {
            name: 'Kebun',
            data: info.farmsPerMonth.reverse(),
          },
        ]);

        setSeriesUser([
          {
            name: 'Petani',
            data: info.usersPerMonth.reverse(),
          },
        ]);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  const fetchProgress = useCallback(async () => {
    setLoading(true);

    // Get API
    await axios
      .all([
        axios.get(
          'https://api.trello.com/1/boards/5fa92f8b6a4594830485752e/cards?key=24a11a4f49230664878f864ffcc71696&token=8f3c5769785dee6b4c14d8d8866cbc7c1714c47cb7fd1e0673433a7ae2479a53&fields=idMembers,idList,dateLastActivity,name,desc,labels,shortUrl,isTemplate,due'
        ),
        axios.get(
          'https://api.trello.com/1/boards/5fa92f8b6a4594830485752e/lists?fields=id,name&key=24a11a4f49230664878f864ffcc71696&token=8f3c5769785dee6b4c14d8d8866cbc7c1714c47cb7fd1e0673433a7ae2479a53'
        ),
      ])
      .then(
        axios.spread((resProgress, resLists) => {
          const progressTmp = [];
          const progressData = resProgress.data;
          const list = resLists.data;
          let prospect = 0;
          let process = 0;
          let ongoing = 0;
          let done = 0;
          let status = '';

          progressData
            .filter(item => item.idList !== '67b3c0a26790c1d55f4bee41')
            .forEach(item => {
              // Cleansing type template
              if (item.isTemplate !== true) {
                // Status
                status = list.find(itemList => itemList.id === item.idList);

                // Calculating progress
                if (status.name === 'Survey') {
                  prospect += 1;
                } else if (
                  status.name === 'Negotiation' ||
                  status.name === 'Agreement'
                ) {
                  process += 1;
                } else if (
                  status.name === 'Initial Installation' ||
                  status.name === 'Finalise' ||
                  status.name === 'Final Tweaks'
                ) {
                  ongoing += 1;
                } else {
                  done += 1;
                }

                // Save data to tmp progress
                progressTmp.push({
                  project: item.name,
                  desc: item.desc,
                  status: status.name,
                  prospect,
                  process,
                  ongoing,
                  done,
                  link: item.shortUrl,
                  due: item.due,
                  created_at: item.dateLastActivity,
                });
              }
            });

          setProgressCalc({
            prospect,
            process,
            ongoing,
            done,
          });
          setProgress(progressTmp);
        })
      )
      .catch(error => console.log('Catch:', error))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const setDefaultIcon = () => {
    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: `${process.env.PUBLIC_URL}/markerHG.svg`,
      iconUrl: `${process.env.PUBLIC_URL}/markerHG.svg`,
      shadowUrl: markerShadow,
      iconSize: new L.Point(38, 95),
      shadowSize: new L.Point(0, 0),
      iconAnchor: new L.Point(20, 70),
      popupAnchor: null,
      shadowAnchor: null,
      
      // iconRetinaUrl: markerIcon2x,
      // iconUrl: markerIcon,
      // iconSize: new L.Point(60, 75),
      // shadowSize: null,
      // popupAnchor: [-3, -76],
      // shadowAnchor: [22, 94]
    });
  };

  useEffect(() => {
    fetchGeneral();
    fetchProgress();
    setDefaultIcon();
  }, []);

  // Controller
  const handleFetch = param => {
    switch (param) {
      case 'general':
        fetchGeneral();
        break;

      case 'project':
        fetchProgress();
        break;

      default:
        break;
    }

    setMenu(param);
  };

  // View
  const headerView = () => (
    <>
      <div className="page-header">
        <div className="row align-items-center">
          {users.status === ADMIN && menu === 'project' && (
            <>
              <button
                aria-label="Back Button"
                type="button"
                onClick={() => setMenu('general')}
                className="col-md-2"
                style={{
                  marginLeft: '8px',
                  marginRight: '10px',
                  height: 40,
                  width: 40,
                  borderRadius: 20,
                  backgroundColor: 'rgba(255, 0, 0, 0)',
                  borderWidth: 0,
                }}>
                <Icon name="ArrowLeft" color="#333" size={20} />
              </button>
            </>
          )}

          {/* Title */}
          <div className="col">
            <div className="page-pretitle">Menu</div>
            <h2 className="page-title">
              <span>Dashboard</span>
              {users.status === ADMIN && menu === 'project' && (
                <>
                  <span className="mx-2"> / </span>
                  <span>Proyek</span>
                </>
              )}
            </h2>
          </div>

          {/* Actions Button */}
          {users.status === ADMIN ? (
            <div className="col-auto ml-auto d-print-none">
              <button
                type="button"
                className={`btn ${
                  menu === 'general' ? 'btn-blue' : 'btn-gray'
                }`}
                style={{marginLeft: 10}}
                onClick={() => handleFetch('general')}>
                <div className="d-flex align-items-center">
                  <Icon name="Layers" size={16} style={{marginRight: 10}} />{' '}
                  <span>Umum</span>
                </div>
              </button>
              <button
                type="button"
                className={`btn ${
                  menu === 'project' ? 'btn-blue' : 'btn-gray'
                }`}
                style={{marginLeft: 10}}
                onClick={() => handleFetch('project')}>
                <div className="d-flex align-items-center">
                  <Icon name="List" size={16} style={{marginRight: 10}} />{' '}
                  <span>Daftar Project</span>
                </div>
              </button>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>

      <div className="row row-deck row-cards mb-3">
        {/* Card all farm */}
        <CardDescMini
          onClick={() => navigate.push('/farm')}
          col="col-md-3"
          bgcolor="bg-green"
          iconName="MapPin"
          title={`${generalInfo ? generalInfo.allFarms : '-'} Kebun`}
          subtitle="Telah Dibuat"
        />

        {/* Card all farmer */}
        <CardDescMini
          onClick={() => navigate.push('/farmer')}
          col="col-md-3"
          bgcolor="bg-blue"
          iconName="Users"
          title={`${generalInfo ? generalInfo.allFarmers : '-'} Petani`}
          subtitle="Telah Terdaftar"
        />

        {/* Card all farm */}
        <CardDescMini
          col="col-md-3"
          bgcolor="bg-orange"
          iconName="Map"
          title={`${
            generalInfo ? Math.round(generalInfo.allSumArea) : '-'
          } m2 atau ${
            generalInfo ? Math.round(generalInfo.allSumArea / 10000) : '-'
          } ha`}
          subtitle="Luas lahan telah terdaftar"
        />

        {/* Card farm register in 30 days */}
        <CardDescMini
          onClick={() => setMenu('project')}
          col="col-md-3"
          bgcolor="bg-orange"
          iconName="Map"
          title={`${progress.length} Proyek`}
          subtitle="Telah terlaksana"
        />
      </div>
    </>
  );

  // const mapsView = () => (
  //   <div className="col-sm-12 col-md-12" style={{height: '400px'}}>
  //     {/* <LoadScript googleMapsApiKey="AIzaSyD-FqHubInKsX5Culh_eyNN0NRQbB9ePFs"> */}
  //     <GoogleMap
  //       mapTypeId="satellite"
  //       center={latlng}
  //       mapContainerClassName="card-body d-inline-block rounded mobile-100"
  //       zoom={4}>
  //       {/* <Polygon
  //         onClick={(e, index) => {
  //           console.log(index);
  //           setModalShow(!modalShow);
  //         }}
  //         paths={gmaps}
  //         options={optionss}
  //       /> */}
  //       {latlng2.map((item, index) => (
  //         <Marker
  //           // key={item.toString}
  //           position={{lat: item.lat, lng: item.lng}}
  //           onClick={() => {
  //             setNameFarm(item.name);
  //             setImageFarm(item.image);
  //             setModalShow(!modalShow);
  //           }}
  //         />
  //       ))}
  //     </GoogleMap>
  //     {/* </LoadScript> */}
  //   </div>
  // );

  const mapsView = () => (
    <div className="col-sm-12 col-md-12" style={{height: '500px'}}>
      <MapContainer
        attributionControl={false}
        center={latlng}
        zoom={4}
        style={{height: '100%', width: '100%', zIndex: 0}}
        className="card-body d-inline-block rounded mobile-100">
        <TileLayer url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" />
        {latlng2.map((item, index) => (
          <Marker
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            position={[item.lat, item.lng]}
            eventHandlers={{
              click: () => {
                setNameFarm(item.name);
                setImageFarm(item.image);
                setModalShow(!modalShow);
              },
            }}
          />
        ))}
      </MapContainer>
    </div>
  );

  const generalView = () => (
    <>
      <div className="row row-deck row-cards">
        {/* Farmers growth */}
        <div className="col-sm-6 col-md-4">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="subheader">Petani Terdaftar</div>
                <div className="ms-auto lh-1">
                  <span className="text-muted">Dalam 30 hari terakhir</span>
                </div>
              </div>
              <div className="d-flex align-items-baseline">
                <div className="h1 mb-0 me-2">
                  {generalInfo ? generalInfo.lastUserData : '-'}
                </div>
                <div className="me-auto">
                  <span className="text-cyan d-inline-flex align-items-center lh-1">
                    Petani
                  </span>
                </div>
              </div>
            </div>
            <ReactApexChart
              className="chart-sm"
              options={optionsUser}
              series={seriesUser}
              height="60"
              type="area"
            />
          </div>
        </div>

        {/* Farm growth */}
        <div className="col-sm-6 col-md-4">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="subheader">Kebun Terdaftar</div>
                <div className="ms-auto lh-1">
                  <span className="text-muted">Dalam 30 hari terakhir</span>
                </div>
              </div>
              <div className="d-flex align-items-baseline">
                <div className="h1 mb-0 me-2">
                  {generalInfo ? generalInfo.lastFarmData : '-'}
                </div>
                <div className="me-auto">
                  <span className="text-cyan d-inline-flex align-items-center lh-1">
                    Kebun
                  </span>
                </div>
              </div>
            </div>
            <ReactApexChart
              className="chart-sm"
              options={optionsFarm}
              series={seriesFarm}
              height="60"
              type="area"
            />
          </div>
        </div>

        {/* Area growth */}
        <div className="col-sm-6 col-md-4">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="subheader">Luas Lahan Terdaftar</div>
                <div className="ms-auto lh-1">
                  <span className="text-muted">Dalam 30 hari terakhir</span>
                </div>
              </div>
              <div className="d-flex align-items-baseline">
                <div className="h1 mb-0 me-2">
                  {generalInfo ? Math.round(generalInfo.lastAreaData) : '-'}
                </div>
                <div className="me-auto">
                  <span className="text-primary d-inline-flex align-items-center lh-1">
                    m<sup>2</sup>
                  </span>
                </div>
              </div>
            </div>
            <ReactApexChart
              className="chart-sm"
              options={options}
              series={seriesArea}
              height="60"
              type="area"
            />
          </div>
        </div>

        {/* Google Maps */}
        {/* {isLoaded ? mapsView() : <LoadingView />} */}
        {mapsView()}
        {/* {users.status === ADMIN ? mapsView() : <></>} */}

        {/* Modal GMaps */}
        <Modal
          isOpen={modalShow}
          onRequestClose={() => setModalShow(false)}
          style={customStyles}
          contentLabel="Example Modal">
          <h2>{nameFarm}</h2>
          <img
            src={`https://habibigarden.com/dashboard/public/dist/img_upload/${imageFarm}`}
            alt=""
            width="200"
            height="200"
          />
          <br />
          <br />
          <button
            className="btn w-full"
            type="button"
            onClick={() => setModalShow(false)}>
            Tutup
          </button>
        </Modal>
      </div>
    </>
  );

  const projectView = () => (
    <>
      <div className="row row-deck row-cards">
        {/* Card Candidate Project */}
        <CardDescMini
          col="col-md-3"
          bgcolor="bg-yellow"
          iconName="List"
          title={`${progressCalc ? progressCalc.prospect : '-'} Proyek`}
          subtitle="Calon korban"
        />

        {/* Card Processed Project */}
        <CardDescMini
          col="col-md-3"
          bgcolor="bg-blue"
          iconName="Book"
          title={`${progressCalc ? progressCalc.process : '-'} Proyek`}
          subtitle="Sedang diproses"
        />

        {/* Card On Going Project */}
        <CardDescMini
          col="col-md-3"
          bgcolor="bg-blue"
          iconName="Tool"
          title={`${progressCalc ? progressCalc.ongoing : '-'} Proyek`}
          subtitle="Sedang dikerjakan"
        />

        {/* Card Finish Project */}
        <CardDescMini
          col="col-md-3"
          bgcolor="bg-cyan"
          iconName="Coffee"
          title={`${progressCalc ? progressCalc.done : '-'} Proyek`}
          subtitle="Selesai"
        />

        {/* Card Project Stage */}
        <div className="col-md-12">
          <div className="card">
            {/* Header */}
            <div className="card-body row align-items-center">
              <div className="col-auto">
                <span className="bg-cyan text-white avatar">
                  <Icon name="BarChart2" />
                </span>
              </div>
              <div className="col">
                <div className="">Progress Proyek</div>
                <div className="text-muted">Total {progress.length} Kebun</div>
              </div>
              <div className="col-auto">
                <a
                  aria-label="Add Button"
                  className="bg-pink text-white avatar"
                  href="https://trello.com/b/MVulqdiF/akan-expired"
                  target="_blank"
                  rel="noreferrer">
                  <Icon name="Plus" />
                </a>
              </div>
            </div>

            {/* Body/Content */}
            <div className="card-table table-responsive">
              <table
                className="table table-vcenter"
                style={{borderTop: '.2px solid #eee'}}>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Proyek Kebun</th>
                    <th colSpan={7}>Progress</th>
                    {/* <th>Status</th> */}
                  </tr>
                </thead>
                <tbody>
                  {progress.map((item, index) => (
                    <tr
                    // key={String(index)}
                    >
                      {/* Index */}
                      <td>
                        {index + 1} {JSON.stringify()}
                      </td>

                      {/* Name */}
                      <td>
                        <span className="text-capitalize list-header-one">
                          {item.project}
                        </span>
                        <br />
                        <span className="text-muted">
                          Sumber:{' '}
                          <a href={item.link} target="_blank" rel="noreferrer">
                            Trello Card
                          </a>
                        </span>
                      </td>

                      {/* Survey Stage */}
                      <td
                        className={
                          item.status === 'Survey'
                            ? 'text-white text-center'
                            : 'text-muted text-center'
                        }>
                        {item.status === 'Survey' ? (
                          <Icon
                            name="FileText"
                            size={30}
                            style={{
                              marginTop: '-3px',
                              margin: 0,
                              background: '#23a2b8',
                              borderRadius: 30,
                              padding: 7,
                            }}
                          />
                        ) : (
                          <Icon
                            name="FileText"
                            size={27}
                            style={{padding: 4}}
                          />
                        )}
                        <div className="text-muted mt-2">Survey</div>
                      </td>

                      {/* Negotiation Stage */}
                      <td
                        className={
                          item.status === 'Negotiation'
                            ? 'text-white text-center'
                            : 'text-muted text-center'
                        }>
                        {item.status === 'Negotiation' ? (
                          <Icon
                            name="Repeat"
                            size={30}
                            style={{
                              marginTop: '-3px',
                              margin: 0,
                              background: '#23a2b8',
                              borderRadius: 30,
                              padding: 7,
                            }}
                          />
                        ) : (
                          <Icon name="Repeat" size={27} style={{padding: 4}} />
                        )}
                        <div className="text-muted mt-2">Negotiation</div>
                      </td>

                      {/* Agreement Stage */}
                      <td
                        className={
                          item.status === 'Agreement'
                            ? 'text-white text-center'
                            : 'text-muted text-center'
                        }>
                        {item.status === 'Agreement' ? (
                          <Icon
                            name="ThumbsUp"
                            size={30}
                            style={{
                              marginTop: '-3px',
                              margin: 0,
                              background: '#23a2b8',
                              borderRadius: 30,
                              padding: 7,
                            }}
                          />
                        ) : (
                          <Icon
                            name="ThumbsUp"
                            size={27}
                            style={{padding: 4}}
                          />
                        )}
                        <div className="text-muted mt-2">Agreement</div>
                      </td>

                      {/* Initial Installation Stage */}
                      <td
                        className={
                          item.status === 'Initial Installation'
                            ? 'text-white text-center'
                            : 'text-muted text-center'
                        }>
                        {item.status === 'Initial Installation' ? (
                          <Icon
                            name="Box"
                            size={30}
                            style={{
                              marginTop: '-3px',
                              margin: 0,
                              background: '#23a2b8',
                              borderRadius: 30,
                              padding: 7,
                            }}
                          />
                        ) : (
                          <Icon name="Box" size={27} style={{padding: 4}} />
                        )}
                        <div className="text-muted mt-2">
                          Initial
                          <br />
                          Installation
                        </div>
                      </td>

                      {/* Finalise Stage */}
                      <td
                        className={
                          item.status === 'Finalise'
                            ? 'text-white text-center'
                            : 'text-muted text-center'
                        }>
                        {item.status === 'Finalise' ? (
                          <Icon
                            name="Codesandbox"
                            size={30}
                            style={{
                              marginTop: '-3px',
                              margin: 0,
                              background: '#23a2b8',
                              borderRadius: 30,
                              padding: 7,
                            }}
                          />
                        ) : (
                          <Icon
                            name="Codesandbox"
                            size={27}
                            style={{padding: 4}}
                          />
                        )}
                        <div className="text-muted mt-2">Finalise</div>
                      </td>

                      {/* Final Tweaks Stage */}
                      <td
                        className={
                          item.status === 'Final Tweaks'
                            ? 'text-white text-center'
                            : 'text-muted text-center'
                        }>
                        {item.status === 'Final Tweaks' ? (
                          <Icon
                            name="Archive"
                            size={30}
                            style={{
                              marginTop: '-3px',
                              margin: 0,
                              background: '#23a2b8',
                              borderRadius: 30,
                              padding: 7,
                            }}
                          />
                        ) : (
                          <Icon name="Archive" size={27} style={{padding: 4}} />
                        )}
                        <div className="text-muted mt-2">
                          Final
                          <br />
                          Tweaks
                        </div>
                      </td>

                      {/* Project Feedback Stage */}
                      <td
                        className={
                          item.status === 'Feedback'
                            ? 'text-white text-center'
                            : 'text-muted text-center'
                        }>
                        {item.status === 'Feedback' ? (
                          <Icon
                            name="MessageCircle"
                            size={30}
                            style={{
                              marginTop: '-3px',
                              margin: 0,
                              background: '#23a2b8',
                              borderRadius: 30,
                              padding: 7,
                            }}
                          />
                        ) : (
                          <Icon
                            name="MessageCircle"
                            size={27}
                            style={{padding: 4}}
                          />
                        )}
                        <div className="text-muted mt-2">
                          Project
                          <br />
                          Feedback
                        </div>
                      </td>

                      {/* Status */}
                      {/* <td className='text-cyan'>{item.status}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Footer */}
            <div className="card-footer">
              <span>
                Keterangan : Klik trello card untuk mengubah posisi progress
                dari kebun
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const handleView = () => {
    switch (menu) {
      case 'general':
        return generalView();

      case 'project':
        return projectView();

      default:
        return null;
    }
  };

  return (
    <div className="container-xl">
      {/* header */}
      {headerView()}

      {/* content */}
      {isLoading ? <LoadingView /> : handleView()}
    </div>
  );
}

export default Dashboard;
