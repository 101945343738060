import React, {useState, useEffect, useCallback} from 'react';
import {Link, useParams, Route} from 'react-router-dom';
import Icon from '../../components/Icon';
import Card from '../../components/SimpleCard';
import LoadingView from '../../components/Loading';
import Dropdown from '../../components/Dropdown';
import {HabibiTable, SelectColumnFilter} from '../../components/HabibiTable';

// Content
import StockIn from './gudang/StockIn';
import useUserStore from '../../stores/Users';

import {getInventories} from '../../actions/warehouse';

const menus = {
  inventory: 'Inventory',
  // Stockin: 'Stok Masuk',
  // stockout: 'Stok Keluar',
  // stockopname: 'Stok Opname',
  // category: 'Kategori',
};

const dropdownItem = routeMenu => {
  const defaultItem = [
    {label: 'Show', action: 'show'},
    {label: 'Edit', action: 'edit'},
  ];

  switch (routeMenu) {
    // case 'growth':
    //   return growthItem;
    default:
      return defaultItem;
  }
};

function Warehouse() {
  const token = useUserStore(state => state.token);
  const [isLoading, setIsLoading] = useState(true);
  const {page} = useParams();
  const [menu, setMenu] = useState('inventory');
  const [data, setData] = useState([]);

  const handleFetch = async fetch => {
    if (!fetch) return;

    setData([]);
    setIsLoading(true);
    try {
      const response = await fetch(token);
      if (response) {
        setData(response);
      }
    } catch (error) {
      console.error(`error ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setMenu(page);

    switch (page) {
      case 'inventory':
        handleFetch(getInventories);
        break;
      default:
        break;
    }
  }, [page]);

  const dropdownView = (routeMenu, routeId) => (
    <div>
      <Dropdown
        name="Action"
        url={`/agriculture/${routeMenu}/${routeId}`}
        items={dropdownItem(routeMenu)}
      />
    </div>
  );

  const tabInventory = () => {
    const columns = [
      {
        Header: 'No',
        accessor: 'no',
        width: '10%',
        Cell: cellInfo => <span>{cellInfo.row.index + 1}</span>,
      },
      {
        Header: 'Gambar',
        accessor: 'image',
        filter: 'includes',
        width: '15%',
        Cell: cellInfo => (
          <img
            src={`https://api.habibigarden.com/articles/${cellInfo.row.original.image}`}
            alt="Habibi Garden"
            style={{height: 50, width: '100%'}}
          />
        ),
      },
      {
        Header: 'Nama',
        accessor: 'name',
        filter: 'includes',
        width: '25%',
      },
      {
        Header: 'Kategori',
        accessor: 'category',
        Filter: SelectColumnFilter,
        filter: 'includes',
        width: '20%',
      },
      {
        Header: 'Qty',
        accessor: 'qty',
        Filter: SelectColumnFilter,
        filter: 'includes',
        width: '15%',
      },
      {
        Header: 'Lokasi',
        accessor: 'location',
        Filter: SelectColumnFilter,
        filter: 'includes',
        width: '15%',
      },
      {
        Header: 'Action',
        Filter: false,
        disableSorting: true,
        Cell: cellInfo => dropdownView('plant', cellInfo.row.original.id),
      },
    ];

    return (
      <>
        <div className="col-md-9">
          <div className="row row-deck row-cards">
            <div className="col-md-12">
              <Card styleBody={{padding: 0, paddingTop: 20}}>
                <HabibiTable
                  title="inventory"
                  columns={columns}
                  data={data}
                  // formOne={2}
                  // formTwo={3}
                  // formThree={null}
                  rowSize={[10, 30, 50]}
                />
              </Card>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleView = () => {
    switch (menu) {
      case 'inventory':
        return tabInventory();
      default:
        return null;
    }
  };

  const headerView = () => {
    const name = menus[menu] || '';
    return (
      <>
        <div className="page-header">
          <div className="row align-items-center">
            {/* Title */}
            <div className="col">
              <div className="page-pretitle">Menu</div>
              <h2 className="page-title">
                <span>Warehouse</span>
                <span className="mx-2"> / </span>
                <span>{name}</span>
              </h2>
            </div>

            {/* Action Button */}
            {menu === 'stockin' ? (
              <div className="col-auto ml-auto d-print-none">
                <Link className="btn btn-gray" to="#belum">
                  <div className="d-flex align-items-center">
                    <Icon
                      name="PlusCircle"
                      size={16}
                      style={{marginRight: 5}}
                    />{' '}
                    <span>Tambah {name}</span>
                  </div>
                </Link>
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  };

  const sidebarView = () => (
    <div className="col-md-3">
      <Route>
        <Card title="Menu" styleBody={{padding: '12px', textAlign: 'center'}}>
          {Object.keys(menus).map(m => (
            <Link
              key={m}
              to={`/warehouse/${m}`}
              className={`btn my-1 w-100 ${menu === m ? 'btn-blue' : ''}`}>
              {menus[m]}
            </Link>
          ))}
        </Card>
      </Route>
    </div>
  );

  return (
    <div className="container-xl">
      {headerView()}

      <div className="row">
        {sidebarView()}

        {/* Content */}
        {isLoading ? (
          <div className="col-md-9">
            <LoadingView />
          </div>
        ) : (
          handleView()
        )}
      </div>
    </div>
  );
}

export default Warehouse;
