import React, { useState, useEffect, useReducer } from 'react';
import axios from 'axios';
import moment from 'moment';
import Select from 'react-select';
import idx from 'idx';
import { useParams } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import Modal from "react-modal";
import { stroke, grid, yaxis } from '../../../components/ChartOptions';
import Card from '../../../components/SimpleCard';
import useUserStore from '../../../stores/Users';
import LoadingView from '../../../components/Loading';
import '../FarmDetail.css';
import 'react-datepicker/dist/react-datepicker.css';
import { newAPI } from '../../../services/baseAPI';

const reducerGraph = (stateGraph, actionGraph) => {
    switch (actionGraph.type) {
        case 'xaxisCategories':
            return {
                ...stateGraph,
                options: { ...stateGraph.options, xaxis: { ...stateGraph.options.xaxis, categories: actionGraph.data } },
            };
        case 'series':
            return {
                ...stateGraph,
                series: [...actionGraph.data]
            };
        case 'yaxisMax':
            return {
                ...stateGraph,
                options: { ...stateGraph.options, yaxis: { ...stateGraph.options.yaxis, max: actionGraph.data } },
            };
        case 'yaxisMin':
            return {
                ...stateGraph,
                options: { ...stateGraph.options, yaxis: { ...stateGraph.options.yaxis, min: actionGraph.data } },
            };
        case 'typeGraph':

            return {
                ...stateGraph,
                options: { ...stateGraph.options, type: actionGraph.data },
            };
        default:
            return { ...stateGraph }
    }
}
const SensorGround = (props) => {
    const { farmId } = useParams();
    const [statusDevice, setStatusDevice] = useState(false);
    const [data, setData] = useState([]);
    const token = useUserStore((state) => state.token);
    const [isLoading, setLoading] = useState(false);
    const [startDate, onChangeStartDate] = useState(moment().subtract(31, 'days').set({
        hour: 0,
        minute: 0,
        second: 0,
    }).toDate());
    const [endDate, onChangeEndDate] = useState(moment().set({
        hour: 23,
        minute: 59,
        second: 59,
    }).toDate());
    const [modalIsOpen, setIsOpen] = useState(false);
    const [dose, setDose] = useState(null);
    const initialStateGraph = {
        options: {
            chart: {
                id: 'graph',
            },
            xaxis: {
                type: 'date',
                min: undefined,
                max: undefined,
                labels: {
                    show: false,
                },
                categories: [],
            },
            yaxis: {
                min: undefined,
                max: undefined,
            },
            noData: {
                text: 'Pilih Data Sensor',
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: undefined,
                    fontSize: '14px',
                    fontFamily: undefined,
                },
            },
            type: 'line',
        },
        series: []
    };
    const keyName = [
        {
            "name": "pH",
            "key": "ph"
        },
        {
            "name": "ppm Pupuk",
            "key": "ppm_pupuk"
        },
        {
            "name": "Volume Torent",
            "key": "vol_torrent"
        },
        {
            "name": "Debit Pemupukan",
            "key": "fert_flow"
        },
        {
            "name": "Debit Pompa",
            "key": "pump_flow"
        },
        {
            "name": "TDS",
            "key": "tds"
        },
        {
            "name": "Suhu (°C)",
            "key": "temp"
        },

    ] // INI YG PERLU DIGANTI AJA

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            borderRadius: "5px",
        },
    };
    const [stateGraph, dispatchGraph] = useReducer(reducerGraph, initialStateGraph);

    Modal.setAppElement("#root");

    const fecthDataFarm = async () => {
        setIsOpen(true);
        await newAPI.get(`/v3/farms/${farmId}/edit`,
            { headers: { Authorization: `Bearer ${token}` } })
            .then((e) => {
                const doseCheck = e.data.dataDevice.find((f) => f.id === `${props.farmCode}_DOSE`);
                if (doseCheck) {
                    setDose(doseCheck.id);
                }
            }).catch((error) => {
                console.log(`Error : ${error}`);
            })
            .finally(() => setIsOpen(false))
    }

    const fetchDataAverage = async () => {
        dispatchGraph({ type: 'series', data: [] })
        const start = moment(startDate).subtract(100, 'seconds').valueOf();
        let end;
        if (moment(endDate).diff(startDate, 'days') + 1 > 31) {
            end = moment(startDate).add(31, 'days').valueOf()
        } else if (moment(endDate).diff(startDate, 'days') + 1 <= 0) {
            end = 'error'
        } else {
            end = moment(endDate).valueOf()
        }
        if (end !== 'error') {
            setIsOpen(true);
            const bodyRaw = {
                farmCode: props.farmCode,
                fromDate: start,
                endDate: end,
                device: 'dose'
            }
            await newAPI
                .post('/v3/farms/graphic', bodyRaw, {
                    headers: {
                        // Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    },
                })
                .then((res) => {
                    if (res.data.Count > 0) {
                        const objectName = [];
                        const dates = res.data.Items.map((date) => moment(date.time).format('DD/MM/YYYY HH:mm:ss'));

                        keyName.forEach((f) => {
                            objectName.push({ 
                                name: `${f.name}`, 
                                data: res.data.Items.map((g) => g?.data[f.key]), 
                                key: f.key 
                            })
                        });
                        dispatchGraph({ type: 'xaxisCategories', data: dates });
                        setData(objectName);
                    } else {
                        setData([]);
                    }
                })
                .catch((err) => console.log(err))
                .finally(() => setIsOpen(false));
        }

    }
    useEffect(() => {
        fecthDataFarm();
    }, [])


    const mean = (number) =>
        number.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0) / number.length;

    const modus = (number) => {
        let mode = 0;
        let count = 0;

        for (let i = 0; i < number.length; i += 1) {
            for (let j = 0; j < i; j += 1) {
                if (number[j] === number[i]) {
                    mode = number[j];
                    count += 1;
                }
            }
        }

        return mode;
    };
    const handlePickData = (check, value) => {
        const dataGraphSeries = stateGraph.series;
        if (check) {
            const dataSensors = data.filter((sensor) => sensor.key === value);
            dispatchGraph({ type: 'series', data: [...dataGraphSeries, ...dataSensors] })
        } else {
            const dataSensors = dataGraphSeries.filter((sensor) => sensor.key !== value);
            dispatchGraph({ type: 'series', data: dataSensors })
        }
    }
    const maxHandleLimit = (value) => {
        if (value) {
            dispatchGraph({ type: 'yaxisMax', data: parseInt(value, 10) })
        } else {
            dispatchGraph({ type: 'yaxisMax', data: undefined })
        }
    }
    const minHandleLimit = (value) => {
        if (value) {
            dispatchGraph({ type: 'yaxisMin', data: parseInt(value, 10) })
        } else {
            dispatchGraph({ type: 'yaxisMin', data: undefined })
        }
    }
    const typeHandleGraph = (type) => {
        dispatchGraph({ type: 'typeGraph', data: type })
    };


    const doseView = (params) => (
        <div id="wrapper">
            <div className="row row-deck row-cards">
                {/* Column Filter */}
                <div className="col-md-12">
                    <Card title="Menu Ground">
                        <div className="row">

                            {/* From Date */}
                            <div className="col-md-4">
                                <label htmlFor="true" className="form-label">
                                    Pilih tanggal{" "}
                                    <small style={{ color: "#999" }}>
                                        <i>(mulai)</i>
                                    </small>{" "}
                                    <span style={{ color: "#ff0000" }}>*</span>
                                </label>
                                <DatePicker
                                    // disabled={farm == null ? true : false}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Mulai tanggal..."
                                    className="form-control datepicker"
                                    selected={startDate}
                                    onChange={(date) => onChangeStartDate(date)}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    maxDate={endDate}
                                />
                            </div>

                            {/* End Date */}
                            <div className="col-md-4">
                                <label htmlFor="true" className="form-label">
                                    <small style={{ color: "#999" }}>
                                        <i>(berakhir)</i>
                                    </small>{" "}
                                    <span style={{ color: "#ff0000" }}>*</span>
                                </label>
                                <DatePicker
                                    // disabled={farm == null ? true : false}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Sampai tanggal..."
                                    className="form-control datepicker"
                                    selected={endDate}
                                    onChange={(date) => onChangeEndDate(date)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    maxDate={moment(startDate).add(31, 'days').toDate()}
                                />
                            </div>

                            <div className="col-md-4" >
                                <label htmlFor="true" className="form-label">
                                    Proses{" "}
                                    <small style={{ color: "#999" }}>
                                        <i>(Maksimal 31 Hari)</i>
                                    </small>{" "}
                                </label>
                                <button
                                    type="button"
                                    className="btn btn-green w-100"
                                    onClick={() => fetchDataAverage()}
                                >
                                    <b>Proses</b>
                                </button>
                            </div>
                            
                            {data.length > 0 ? <hr style={{ marginTop: '20px', marginBottom: '15px' }} /> : null}

                            {/* pilih sensor */}
                            {data.length > 0 && (
                                <>
                                    <div className="col-md-12">
                                        <label htmlFor="true" className="form-label">
                                            Pilih Sensor
                                        </label>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row" style={{ marginBottom: '4px' }}>
                                            {keyName.map(e => (
                                                <div className="col-md-4" key={e.key}>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            value={e.key}
                                                            onChange={(value) => handlePickData(value.target.checked, value.target.value)}
                                                        />
                                                        <span style={{ marginLeft: '4px' }}>{e.name}</span>
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )}

                        </div>
                    </Card>
                </div>

                {/* Chart */}
                <div className="col-md-12">
                    <Card
                        title='Sensor Habibidose'
                    >
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="true" className="form-label">
                                    Jenis Grafik
                                    <select
                                        className="form-control"
                                        onChange={(e) => typeHandleGraph(e.target.value)}
                                    >
                                        <option value="line">Line</option>
                                        <option value="area">Area</option>
                                        <option value="bar">Bar</option>
                                    </select>
                                </label>
                            </div>
                            <div className="col-4">
                                <label htmlFor="true" className="form-label">
                                    Min
                                    <input
                                        name="min"
                                        type="number"
                                        className="form-control"
                                        onChange={(e) => minHandleLimit(e.target.value)}
                                    />
                                </label>
                            </div>
                            <div className="col-4">
                                <label htmlFor="true" className="form-label">
                                    Max
                                    <input
                                        name="max"
                                        type="number"
                                        className="form-control"
                                        onChange={(e) => maxHandleLimit(e.target.value)}
                                    />
                                </label>
                            </div>
                        </div>
                        {/* sensor chart */}
                        <ReactApexChart
                            height={400}
                            options={stateGraph.options}
                            series={stateGraph.series}
                            type={stateGraph.options.type}
                        />


                        {/* <table className="text-center mt-4 table table-responsive table-bordered">
                                <thead>
                                    <tr>
                                        <th className="text-nowrap text-center">Zona</th>
                                        <th>Min</th>
                                        <th>Max</th>
                                        <th>Rata-Rata</th>
                                        <th>Modus</th>
                                        <th>Pengairan</th>
                                        <th>Pemupukan</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {zoneLoop.map((item) => (
                                        <tr key={item.toString()}>
                                            <td>{item}</td>
                                            <td>{Math.min(...dataY.activeSensorZone[item])}</td>
                                            <td>{Math.max(...dataY.activeSensorZone[item])}</td>
                                            <td>{mean(dataY.activeSensorZone[item]).toFixed(2)}</td>
                                            <td>{modus(dataY.activeSensorZone[item])}</td>
                                            <td>
                                                {dataY.relayWaterZone[item].filter((status) => status === 1).length}x /{' '}
                                                {idx(ground[0], (_) => _[`sensorZ${item}`].lp)
                                                    ? parseInt(ground[0][`sensorZ${item}`].lp, 10) *
                                                    dataY.relayWaterZone[item].filter((status) => status === 1).length
                                                    : 0}{' '}
                                                detik
                                            </td>
                                            <td>
                                                {dataY.relayFertZone[item].filter((status) => status === 1).length}x /{' '}
                                                {idx(ground[0], (_) => _[`sensorZ${item}`].lp)
                                                    ? parseInt(ground[0][`sensorZ${item}`].lp, 10) *
                                                    dataY.relayFertZone[item].filter((status) => status === 1).length
                                                    : 0}{' '}
                                                detik
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table> */}
                    </Card>
                </div>
            </div>
            <Modal
                isOpen={modalIsOpen}
                style={customStyles}
                overlayClassName="OverlayReport"
                contentLabel="Example Modal"
            >
                <div className="container h-100">
                    <div className="d-flex align-items-center flex-column justify-content-center h-100">
                        <img
                            className="img"
                            style={{ marginBottom: 20, marginTop: 10 }}
                            src={`${process.env.PUBLIC_URL}/dist/img/preloader.gif`}
                            alt="Logo"
                        />
                        <p>Sedang diproses, tunggu beberapa saat...</p>
                    </div>
                </div>
            </Modal>
        </div>
    );
    const handleView = (params) => {
        if (dose !== null) {
            return doseView();
        }

        return (
            <div className="col-md-12">
                <Card title="Informasi" className="border-warning text-warning">
                    Mohon maaf, kebun ini belum memasang Habibi Dose.
                    <br />
                    Silahkan hubungi nomor berikut 0812-7763-9698 untuk memasang Habibi Dose.
                </Card>
            </div>
        );
    }

    return (
        <>
            {/* content */}
            {handleView()}
        </>
    );
};

SensorGround.propTypes = {
    farmCode: PropTypes.string.isRequired, // arrayOf
};

export default SensorGround;
