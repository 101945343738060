/* eslint-disable react/prop-types */
import React from 'react';
import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet';

function MapsBasic({
  latitude,
  longitude,
  zoom,
  style,
  className,
  draggable,
  eventHandlers,
  mapsProvider = 'google',
}) {
  let mapsType = '';
  if (mapsProvider === 'google') {
    mapsType = 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}';
  } else {
    mapsType = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  }
  const styleTmp = {height: '100%', width: '100%', zIndex: 0, ...style};
  
  return (
    <MapContainer
      attributionControl={false}
      center={{
        lat: latitude,
        lng: longitude,
      }}
      zoom={zoom}
      style={styleTmp}
      className={`card-body d-inline-block rounded mobile-100 ${className}`}
    >
      <TileLayer url={mapsType} />
      <Marker
        draggable={draggable}
        position={[latitude, longitude]}
        eventHandlers={eventHandlers}
      />
    </MapContainer>
  );
}

function MapsExpert({
  latitude,
  longitude,
  zoom,
  style,
  className,
  mapsProvider = 'google',
  children
}) {
  let mapsType = '';
  if (mapsProvider === 'google') {
    mapsType = 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}';
  } else {
    mapsType = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  }
  const styleTmp = {height: '100%', width: '100%', zIndex: 0, ...style};
  
  return (
    <MapContainer
      attributionControl={false}
      center={{
        lat: latitude,
        lng: longitude,
      }}
      zoom={zoom}
      style={styleTmp}
      className={`card-body d-inline-block rounded mobile-100 ${className}`}
    >
      <TileLayer url={mapsType} />
      {children}
    </MapContainer>
  );
}

export {MapsBasic, MapsExpert};
